// https://bsky.app/profile/icarus-games.co.uk/post/3lhmaekqa222m
const AC_LOOKUP: Record<number, number[]> = {
  1: [9, 13, 18],
  2: [6, 13, 19],
  3: [11, 14, 18],
  4: [7, 15, 20],
  5: [9, 15, 20],
  6: [12, 16, 18],
  7: [12, 16, 18],
  8: [13, 16, 18],
  9: [14, 17, 19],
  10: [15, 17, 20],
  11: [15, 17, 20],
  12: [16, 18, 20],
  13: [16, 18, 19],
  14: [18, 18, 19],
  15: [16, 18, 19],
  16: [16, 19, 20],
  17: [17, 19, 20],
  18: [20, 20, 20],
  19: [19, 20, 20],
  20: [19, 20, 21]
}

export class ACLookup {
  private static lookup(level: number, index: number): number {
    if (level < 1 || level > 20) {
      console.error('Invalid level when looking up AC', level, index)
      return 10
    }
    if (index < 0 || index > 2) {
      console.error('Invalid index looking up AC', level, index)
      return 10
    }

    return AC_LOOKUP[level][index]
  }

  static averageACForLevel(level: number): number {
    return this.lookup(level, 1)
  }

  static minACForLevel(level: number): number {
    return this.lookup(level, 0)
  }

  static maxACForLevel(level: number): number {
    return this.lookup(level, 2)
  }
}
