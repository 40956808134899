import { TurnAction } from '../DDB/TurnAction'
import { NumberMap } from '../Common/Interfaces'
import { DamageData } from '../Common/Interfaces'

export function calculateAverageDamageForTurns(allTurns: TurnAction[], acs: number[]): NumberMap[] {
  const emptyResult = [new NumberMap(), new NumberMap(), new NumberMap(), new NumberMap()]
  if (allTurns.length === 0) {
    return emptyResult
  }

  const validTurns = allTurns.filter((turn) => turn.attackAction !== undefined)
  if (validTurns.length === 0) {
    // Only have stubbed out turns, that's ok
    return emptyResult
  }

  if (acs === undefined) {
    console.error('No ACs for extended damage data')
    return emptyResult
  }

  // TODO sanity check - validate every turn's extendedDamageDataACs is the same as acs.
  const firstAC: number = acs[0]
  const averageDamagePerAC: NumberMap[] = [new NumberMap(), new NumberMap(), new NumberMap(), new NumberMap()]
  for (const turn of validTurns) {
    const scenarios = scenariosForTurn(turn)

    for (let i = 0; i < scenarios.length; i++) {
      const dataSource = scenarios[i].damage
      if (!dataSource) {
        console.warn(`No damage data for turn ${turn.id} scenario ${i}?`)
        continue
      }
      for (const ac of acs) {
        const damageDatum = dataSource[ac - firstAC]
        if (damageDatum) {
          averageDamagePerAC[i][ac] = (averageDamagePerAC[i][ac] || 0) + damageDatum.average
        } else {
          console.warn(`No damage data for ${ac} in turn ${turn.id}?`)
        }
      }
    }
  }

  return averageDamagePerAC
}

function scenariosForTurn(turn: TurnAction) {
  return [turn.disadvantageScenario, turn.flatScenario, turn.advantageScenario, turn.userScenario]
}

function turnDataForSourceID(turn: TurnAction, id: number) {
  const scenarios = scenariosForTurn(turn)
  if (!scenarios[id].damage) {
    console.log(`No damage data for turn ${turn.id} scenario ${id}?`)
  }
  return scenarios[id].damage!
}

export function sumDamageArrays(allTurns: TurnAction[], acs: number[]): number[] {
  // TODO later make this take any length array of ACs, not just hard coded 2
  const damageTotals = new Array(acs.length).fill(0)
  const rowCount = acs.length
  for (const turnAction of allTurns) {
    const damageData = turnAction.asSpecifiedDamageDataForACs(acs)
    for (let i = 0; i < rowCount && damageData.length >= rowCount; i++) {
      damageTotals[i] += damageData[i].average
    }
  }
  return damageTotals
}

export function calculateDamagesDataForAC(allTurns: TurnAction[], acs: number[], chartAC: number): DamageData[] {
  const emptyDamageData = { average: 0, total: 0, expression: '', percentiles: {} }
  const damageDataArray: DamageData[] = [emptyDamageData, emptyDamageData, emptyDamageData]

  const validTurns = allTurns.filter((turn) => turn.attackAction !== undefined)
  if (validTurns.length === 0) {
    return damageDataArray
  }

  for (let sourceID = 0; sourceID < 4; sourceID++) {
    if (validTurns.length > 0) {
      const firstAC: number = acs[0]
      const targetAC = chartAC - firstAC
      const firstTurn = validTurns[0]

      let damageData = turnDataForSourceID(firstTurn, sourceID)[targetAC]
      damageData = spanDamageDataGaps(damageData)
      let percentileValues = Object.values(damageData?.percentiles ?? {})
      for (let i = 1; i < validTurns.length; i++) {
        const turn = validTurns[i]
        let nextDamageData = turnDataForSourceID(turn, sourceID)[targetAC]
        nextDamageData = spanDamageDataGaps(nextDamageData)
        const nextPercentileValues = Object.values(nextDamageData.percentiles)
        percentileValues = convolve(percentileValues, nextPercentileValues)
      }

      const totalDiceString = validTurns.map((turn) => `(${turn.diceModStringForAC(chartAC)})`).join('+')
      damageDataArray[sourceID] = {
        average: 0,
        total: 0,
        expression: totalDiceString,
        percentiles: { ...percentileValues }
      }
    }
  }

  return damageDataArray
}

function convolve(a: number[], b: number[]): number[] {
  const result = new Array(a.length + b.length - 1).fill(0)
  for (let i = 0; i < a.length; i++) {
    for (let j = 0; j < b.length; j++) {
      result[i + j] += a[i] * b[j]
    }
  }
  return result
}

export function spanDamageDataGaps(damageData: DamageData): DamageData {
  const keys = Object.keys(damageData?.percentiles ?? {})
  if (damageData) {
    // Fill in any missing damage values with 0
    const keyNumbers = keys.map(Number)
    const maxKey = Math.max(...keyNumbers)
    for (let i = 0; i < maxKey; i++) {
      if (!keyNumbers.includes(i)) {
        if (damageData?.percentiles[i] === undefined) {
          damageData.percentiles[i] = 0
        }
      }
    }
    return damageData
  }

  return { average: 0, total: 0, expression: '', percentiles: [] }
}
