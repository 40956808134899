export type WeaponProperty =
  | 'Two-Handed'
  | 'Heavy'
  | 'Range'
  | 'Finesse'
  | 'Versatile'
  | 'Light'
  | 'Thrown'
  | 'Ammunition'
  | 'Loading'
  | 'Ammunition (Firearms)'
  | 'Special'
  | 'Reach'
  | 'Nick'
  | 'Vex'
  | 'Cleave'
  | 'Graze'
  | 'Push'
  | 'Sap'
  | 'Topple'
  | 'Slow'

// A bit clumsy…
export const knownMasteries: WeaponProperty[] = [
  'Reach',
  'Nick',
  'Vex',
  'Cleave',
  'Graze',
  'Push',
  'Sap',
  'Topple',
  'Slow'
]

export class WeaponAttributes {
  isPolearm: boolean = false
  is2024Polearm: boolean = false // Quarterstaff, a Spear, or a weapon that has the Heavy and Reach properties
  isBow: boolean = false
  isCrossbow: boolean = false
  isRanged: boolean = false
  isOffHand: boolean = false
  isAttuned: boolean = false
  isHex: boolean = false
  isPact: boolean = false
  isCustomized: boolean = false
  isMonkWeapon: boolean = false
  isMagical: boolean = false

  weaponMastery?: WeaponProperty
  properties: WeaponProperty[] = []
}
