import { Dice } from '../Dice'
import { FeatureSource } from '../FeatureSource'
import { FeatureParserBase } from './FeatureParserBase'

export class FeatureParserSpellEffects {
  static parse(parser: FeatureParserBase): boolean {
    const { feature, action, character } = parser
    const { only, effects, name } = feature
    if (feature.featureSource !== FeatureSource.Spell) return false

    // 2024
    if (name === `Crusader's Mantle`) {
      only.weaponOrUnarmed = true
      effects.additionalDamageOnHitDice = new Dice(action.dice)
      return true
    }

    if (name === 'Ambush Prey') {
      only.attackRoll = true
      only.oncePerTurn = true

      if (action.spellAttributesCache.upcastDie) {
        effects.additionalDamageOnHitDice = action.spellAttributesCache.upcastDie.copy()
      } else {
        effects.additionalDamageOnHitDice = new Dice(action.dice)
      }
      return true
    }

    if (name === 'Magic Weapon') {
      // 2014
      const slotLevel = character.highestLevelSpellSlot()
      const bonus = slotLevel >= 6 ? 3 : slotLevel >= 3 ? 2 : 1
      only.weapon = true
      effects.additionalToHitDice = Dice.flatAmountDie(bonus)
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(bonus)
      return true
    }

    if (name === 'Absorb Elements') {
      if (!action.dice) {
        // TODO: Is this another isSubclassFeature?
        return false
      }
      only.meleeOrUnarmed = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = new Dice(action.dice)
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'Zephyr Strike') {
      if (!action.dice) {
        // TODO: Is this another isSubclassFeature?
        return false
      }
      only.oncePerTurn = true
      only.weapon = true
      effects.additionalDamageOnHitDice = new Dice(action.dice)
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'Flame Arrows') {
      if (!action.dice) {
        // TODO: Is this another isSubclassFeature?
        return false
      }
      only.weaponProperties = ['Range']
      effects.additionalDamageOnHitDice = new Dice(action.dice)
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'Hex') {
      feature.isBuff = true
      effects.additionalDamageOnHitDice = new Dice(action.dice!)
      return true
    }

    if (name === 'Holy Weapon') {
      feature.isBuff = true
      only.weapon = true
      effects.additionalDamageOnHitDice = Dice.Create(2, 8)
      effects.doNotPruneSpellSource = true
      return true
    }

    if (name === 'Conjure Minor Elementals') {
      feature.isBuff = true
      only.weapon = true
      if (action.spellAttributesCache && action.spellAttributesCache.upcastDie) {
        effects.additionalDamageOnHitDice = action.spellAttributesCache.upcastDie.copy()
      }
      return true
    }

    if (name === 'Shadow of Moil') {
      feature.isBuff = true
      only.weapon = true
      effects.additionalDamageOnHitDice = action.dice ? new Dice(action.dice) : Dice.Create(2, 8)
      return true
    }

    if (name === 'Fount of Moonlight') {
      feature.isBuff = true
      only.weapon = true
      effects.additionalDamageOnHitDice = action.dice ? new Dice(action.dice) : Dice.Create(2, 6)
      return true
    }

    if (name === 'Swift Quiver') {
      //feature.isBuff = true // TODO should this be on?
      effects.extraAttackThisAction = true
      only.bow = true
      feature.notes = 'Extra attack with arrows or bolts this action'
      return true
    }

    if (name === 'Guardian of Nature') {
      feature.isBuff = true
      only.attackRoll = true
      effects.advantage = true
      effects.additionalDamageOnHitDice = Dice.Create(1, 6)
      return true
    }

    if (name === 'Elemental Weapon') {
      const slotLevel = character.highestLevelSpellSlot()
      const bonus = slotLevel >= 7 ? 3 : slotLevel >= 5 ? 2 : 1
      only.weapon = true
      effects.additionalToHitDice = Dice.flatAmountDie(bonus)
      effects.additionalDamageOnHitDice = Dice.Create(bonus, 4, bonus)
      return true
    }

    if (name === "Hunter's Mark") {
      feature.isBuff = true
      only.attackRoll = true
      effects.isHuntersMark = true
      effects.additionalDamageOnHitDice = new Dice(action.dice!)
      effects.huntersMarkDice = effects.additionalDamageOnHitDice.copy()

      return true
    }

    if (name.includes('Smite')) {
      const higherLevelDice = action.higherLevelDice ? new Dice(action.higherLevelDice) : undefined
      const spellLevel = action.level
      const upcastLevel = action.upcastLevel ?? spellLevel

      effects.additionalDamageOnHitDice = new Dice(action.dice!)

      if (higherLevelDice !== undefined) {
        effects.additionalDamageOnHitDice.diceCount += upcastLevel - spellLevel
      }

      feature.isBuff = true
      only.oncePerTurn = true
      only.weaponOrUnarmed = true

      effects.smiteDice = effects.additionalDamageOnHitDice.copy()

      if (name === 'Blinding Smite') {
        effects.nextAttacksHaveAdvantage = true
        feature.notes = `+${effects.smiteDice.diceString()} dmg next hit and next attacks have advantage (CON save)`
        // For later: CON save
      } else if (name === 'Shining Smite') {
        effects.nextAttacksHaveAdvantage = true
        feature.notes = `+${effects.smiteDice.diceString()} dmg next hit and next attacks have advantage`
        // No save
      } else if (name === 'Stunning Smite') {
        effects.nextAttacksHaveAdvantage = true
        feature.notes = `+${effects.smiteDice.diceString()} dmg next hit and next attacks have advantage (WIS save)`
        // For later: WIS save
      } else if (name === 'Thunderous Smite') {
        effects.nextAttacksHaveAdvantage = true
        feature.notes = `+${effects.smiteDice.diceString()} dmg next hit and next attacks have advantage (STR save)`
        // For later: STR save
      }

      return true
    }

    if (name === 'Spirit Shroud') {
      const dice = new Dice(action.dice)
      const highestSlot = character.highestLevelSpellSlot()
      dice.diceCount = Math.floor((highestSlot - 1) / 2)
      only.attackRoll = true
      effects.additionalDamageOnHitDice = dice
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'Divine Favor') {
      only.weapon = true
      effects.additionalDamageOnHitDice = new Dice(action.dice)
      return true
    }

    if (name === `Tenser's Transformation`) {
      only.weapon = true
      effects.advantage = true
      effects.additionalDamageOnHitDice = action.dice ? new Dice(action.dice) : Dice.Create(2, 12)
      effects.secondAttack = true
      feature.notes = 'Second attack, +2d12 dmg per hit with weapon, with advantage'
      return true
    }

    if (name === 'Enlarge/Reduce') {
      only.weaponOrUnarmed = true
      effects.additionalDamageOnHitDice = action.dice ? new Dice(action.dice) : Dice.Create(1, 4)
      return true
    }

    if (name === `Tasha's Otherworldly Guise`) {
      only.weapon = true
      effects.secondAttack = true
      effects.useSpellcastingAbilityModForAttacks = true
      feature.notes = 'Second attack, use spellcasting mod for attacks and damage'
      return true
    }

    if (name === 'Foresight') {
      effects.advantage = true
      only.attackRoll = true
      return true
    }

    if (name === 'Booming Blade') {
      only.oncePerTurn = true
      only.meleeWeapon = true
      effects.isBoomingBlade = true

      // TODO - this is a cantrip, so it should prevent other melee attacks this turn
      const cantripDieCount = character.cantripDieCount()
      effects.additionalDamageOnHitDice =
        cantripDieCount - 1 > 0 ? Dice.Create(cantripDieCount - 1, 8) : Dice.flatAmountDie(0)

      effects.additionalDamageOnMoveDice = Dice.Create(cantripDieCount, 8)
      if (!effects.additionalDamageOnHitDice) {
        feature.notes = 'Enemy sheathed in booming energy'
      }
      return true
    }

    if (name === 'Booming Blade: Target Moves') {
      only.oncePerTurn = true
      effects.isBoomingBladeTargetMoved = true // todo - so stupid
      effects.additionalDamageOnMoveDice = Dice.Create(character.cantripDieCount(), 8)
      feature.notes = `+${effects.additionalDamageOnMoveDice.diceString()} dmg if target moves`
      return true
    }

    if (name === 'True Strike') {
      only.weapon = true
      only.oncePerTurn = true
      effects.useSpellcastingAbilityModForAttacks = true

      const bbDiceCount = character.cantripDieCount() - 1

      if (bbDiceCount > 0) {
        effects.additionalDamageOnHitDice = Dice.Create(bbDiceCount, 6)
        feature.notes = `Use spellcasting mod, +${effects.additionalDamageOnHitDice.diceString()} dmg next weapon attack`
      } else {
        feature.notes = `Use spellcasting modifier for next weapon attack`
      }
      return true
    }

    if (name === 'Green-Flame Blade') {
      // TODO - this is a cantrip, so it should prevent other melee attacks this turn
      const gfbDiceCount = character.cantripDieCount() - 1
      const spellcastingMod = character.spellcastingAbilityModifier()
      only.weapon = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(2 * gfbDiceCount, 8, spellcastingMod)
      return true
    }

    if (name === 'Toll the Dead: Injured') {
      const cantripDieCount = character.cantripDieCount()
      only.spellNamed = 'Toll the Dead'
      effects.replacementDamageDice = Dice.Create(cantripDieCount, 12)
      feature.notes = `Toll the Dead deals ${cantripDieCount}d12 to injured targets`
      return true
    }

    // For now don't log unknown spells, as most of them aren't handled
    //this.logUnknownAction(action)

    return false
  }
}
