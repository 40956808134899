import React from 'react'

import { Text, useTheme, Tag, Flex, Box, Button, HStack } from '@chakra-ui/react'
import { TableContainer, Table, Tbody, Th, Tr, Td } from '@chakra-ui/react'
import { Menu, MenuButton, MenuOptionGroup, MenuList, MenuItemOption } from '@chakra-ui/react'
import { ActionLevelMap, NumberMap } from '../../Common/Interfaces'
import { Dispatcher } from '../../Common/Types'
import { TurnEngine } from '../../Data/TurnEngine'
import { TurnAction } from '../../DDB/TurnAction'
import { IconHexagonLetterE, IconHexagonLetterA, IconHexagonLetterD } from '@tabler/icons-react'
import { DamageData } from '../../Common/Interfaces'
import { DamageCell, IconTooltip, TableActionButton, WrappingTd } from '../../Common/UIComponents'
import { ActionBox } from './ActionBox'

// TODO some kind of theme variant
export const TABLE_HEADER_COLOR: string = 'gray.400'

export function TurnTable({
  turnEngine,
  actionIdState,
  advantageState,
  targetAC
}: {
  turnEngine: TurnEngine
  actionIdState: [ActionLevelMap[], Dispatcher<ActionLevelMap[]>]
  advantageState: [NumberMap, Dispatcher<NumberMap>]

  targetAC: number
}) {
  const [actionIdList, setActionIdList] = actionIdState
  const [advantageOverrides, setAdvantageOverrides] = advantageState
  const generateRows = (actions: TurnAction[], isValid: (action: TurnAction) => boolean) => {
    return actions.map((action) => {
      return (
        <TurnRow
          key={action.id}
          turnAction={action}
          turnEngine={turnEngine}
          isValid={isValid(action)}
          targetAC={targetAC}
          actionIdState={[actionIdList, setActionIdList]}
          advantageState={[advantageOverrides, setAdvantageOverrides]}
        />
      )
    })
  }

  const totalAttackCount = turnEngine.totalAttackCount
  const totalBAAttackCount = turnEngine.totalBonusActionAttackCount
  const actionRows = generateRows(turnEngine.turnActions, (action) => action.attackNumber <= totalAttackCount)
  const bonusActionRows = generateRows(
    turnEngine.bonusTurnActions,
    (action) => action.attackNumber <= totalBAAttackCount
  )
  const reactionRows = generateRows(turnEngine.reactionTurnActions, (action) => action.attackNumber === 1)
  const actionsRequireSave = turnEngine.actionRequiresSave
  const baRequiresSave = turnEngine.bonusActionRequiresSave
  const bonusActionsFirst = turnEngine.bonusActionFirst

  const damageRiderRows = generateRows(
    turnEngine.oncePerTurnDamageRiderTurnActions,
    (action) => action.attackNumber <= totalAttackCount
  )

  return (
    <TableContainer width="100%" pb={2}>
      <Table variant="unstyled" size="sm">
        <Tbody>
          {bonusActionsFirst && bonusActionRows.length > 0 && (
            <TurnHeaderRow title="Bonus Actions" requiresSave={baRequiresSave} targetAC={targetAC} />
          )}
          {bonusActionsFirst && bonusActionRows}
          <TurnHeaderRow title="Actions" requiresSave={actionsRequireSave} targetAC={targetAC} />
          {actionRows}
          {!bonusActionsFirst && bonusActionRows.length > 0 && (
            <TurnHeaderRow title="Bonus Actions" requiresSave={baRequiresSave} targetAC={targetAC} pt={2} />
          )}
          {!bonusActionsFirst && bonusActionRows}

          {turnEngine.reactionTurnActions.length > 0 && (
            <TurnHeaderRow title="Reaction" requiresSave={turnEngine.reactionRequireSave} targetAC={targetAC} pt={2} />
          )}
          {turnEngine.reactionTurnActions.length > 0 && reactionRows}
          {turnEngine.oncePerTurnDamageRiderTurnActions.length > 0 && (
            <DamageRiderHeaderRow title="Once Per Turn On Hit" pt={2} />
          )}

          {turnEngine.oncePerTurnDamageRiderTurnActions.length > 0 && damageRiderRows}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export function TurnHeaderRow({
  title,
  requiresSave,
  targetAC,
  pt
}: {
  title: string
  requiresSave: boolean
  targetAC: number
  pt?: number
}) {
  const save = targetAC - 10
  // ABC
  const saveHeaderTitle = requiresSave ? (save < 0 ? `${save} save` : `+${save} save`) : `AC ${targetAC}`

  return (
    <Tr textColor={TABLE_HEADER_COLOR} verticalAlign={'bottom'}>
      <Th fontSize="medium">
        <Text pt={pt}>{title}</Text>
      </Th>
      <Th>
        <Text>To Hit / DC</Text>
      </Th>
      {/* <Th>To hit %</Th> */}
      <Th>Damage</Th>
      <Th width="15%">{saveHeaderTitle}</Th>
    </Tr>
  )
}

export function DamageRiderHeaderRow({ title, pt }: { title: string; pt?: number }) {
  return (
    <Tr textColor={TABLE_HEADER_COLOR} whiteSpace="normal" wordBreak="break-word">
      <Th fontSize="medium">
        <Flex alignItems="center" pt={pt}>
          <Text>{title}</Text>
          <IconTooltip>These effects apply once per turn on a successful hit.</IconTooltip>
        </Flex>
      </Th>
      <Th verticalAlign="bottom">
        <Text>Odds</Text>
      </Th>
      <Th verticalAlign="bottom">Damage</Th>
      <Th width="15%" verticalAlign="bottom">
        Actual
      </Th>
    </Tr>
  )
}

export function TurnRow({
  turnAction,
  turnEngine,
  isValid,
  actionIdState,
  advantageState,
  targetAC
}: {
  turnAction: TurnAction
  turnEngine: TurnEngine
  isValid: boolean
  actionIdState: [ActionLevelMap[], Dispatcher<ActionLevelMap[]>]
  advantageState: [NumberMap, Dispatcher<NumberMap>]
  targetAC: number
}) {
  const theme = useTheme()
  const [actionIdList, setActionIdList] = actionIdState
  const [advantageOverrides, setAdvantageOverrides] = advantageState
  if (!turnAction) {
    return null
  }

  const level = turnAction.attackAction?.turnLevel

  const handleRemoveButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const [clickedId, level] = event.currentTarget.value.split(',')

    const index = actionIdList.findIndex(
      (item) => Number(item.actionId) === Number(clickedId) && Number(level) === Number(item.level)
    )
    const newList = [...actionIdList]
    if (index !== -1) {
      newList.splice(index, 1)
      setActionIdList(newList)
    } else {
      console.error(`Could not find clicked ID ${clickedId} level ${level}?`)
    }
  }

  const name = turnAction.name()
  const noOverrideValue = -100

  const toHit = turnAction.consolidatedToHitString()
  // TODO - move this advantage logic into TUrnAction or something else…

  let advantage: number = turnAction.advantageType()

  let overridenAdvantage: boolean = false
  if (advantageOverrides[turnAction.id] !== undefined) {
    advantage = advantageOverrides[turnAction.id]
    overridenAdvantage = true
  }

  const elvenAccuracy = turnAction.isCompanion() ? false : turnEngine.elvenAccuracy
  const advIcon = <IconHexagonLetterA stroke={1} size={20} color={theme.colors.green[200]} />
  const disIcon = <IconHexagonLetterD stroke={1} size={20} color={theme.colors.red[200]} />
  const eadvIcon = <IconHexagonLetterE stroke={1} size={20} color={theme.colors.green[200]} />

  let advantageIcon = undefined
  if (advantage === 1) advantageIcon = advIcon
  else if (advantage === 2) advantageIcon = eadvIcon
  else if (advantage === -1) advantageIcon = disIcon

  const aTitle = `Advantage${elvenAccuracy ? ' (Elven Accuracy)' : ''}`
  const advValue = elvenAccuracy ? '2' : '1'

  let toHitElement = <Box />

  if (turnAction.damageRider && turnAction.userScenario.hitMultiplier) {
    // HACK - we shouldn't have to be checking the asSpecifiedDamageMultipliers
    // TODO this -5 is the first AC, to map it back to index.
    const mult = 100 * turnAction.userScenario.hitMultiplier[targetAC - 5]
    toHitElement = <Text>{mult.toFixed(0)}%</Text>
  } else if (toHit) {
    const innerElement = (
      <Flex alignItems={'center'}>
        {advantageIcon}

        <Text>
          {toHit}
          {overridenAdvantage ? '*' : ''}
        </Text>

        {advantageIcon && <Box width={1} />}
      </Flex>
    )

    if (!turnAction.isAttackRoll()) {
      toHitElement = <Box ps={2}>{innerElement}</Box>
    } else {
      toHitElement = (
        <Menu isLazy>
          <MenuButton
            as={Button}
            variant="hoverButton"
            py={0}
            px={2}
            height={6}
            position="relative"
            left="-2"
            _hover={{
              bgColor: 'gray.600'
            }}
          >
            {innerElement}
          </MenuButton>
          <MenuList minW="0">
            <MenuOptionGroup
              defaultValue={overridenAdvantage ? advantage.toString() : noOverrideValue.toString()}
              type="radio"
              onChange={(value) => {
                const newValue: number = Array.isArray(value) ? parseInt(value[0]) : parseInt(value)
                const newAdvantageOverrides: NumberMap = advantageOverrides.copy()
                if (newValue === noOverrideValue) {
                  delete newAdvantageOverrides[turnAction.id]
                  setAdvantageOverrides(newAdvantageOverrides)
                } else {
                  newAdvantageOverrides[turnAction.id] = newValue
                  setAdvantageOverrides(newAdvantageOverrides)
                }
              }}
            >
              <MenuItemOption value={noOverrideValue.toString()}>Default</MenuItemOption>
              <MenuItemOption value={advValue}>{aTitle}</MenuItemOption>
              <MenuItemOption value="0">Flat</MenuItemOption>
              <MenuItemOption value="-1">Disadvantage</MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      )
    }
  }

  const damage = turnAction.consolidatedDamageStringForLevel(Number(level) || 1, true)
  const attributes = turnAction.attributes()

  const damageData: DamageData[] = turnAction.asSpecifiedDamageDataForACs([targetAC])
  const id = attributes.id

  let button = <TableActionButton id={id} value={[id, level].join(',')} add={false} onClick={handleRemoveButtonClick} />

  let showLevel = false
  let turnLevel = 1
  if (name === '') {
    button = <Box width="24px" height="5px" />
  } else {
    if (turnAction.attackAction && turnAction.attackAction.turnLevel > 0) {
      turnLevel = turnAction.attackAction.turnLevel
      showLevel = true
    }
  }

  return (
    <Tr fontSize={'medium'}>
      <WrappingTd>
        <HStack>
          {turnAction.damageRider && <Box ps={6} />}

          {!turnAction.damageRider && turnAction.attackNumber && (
            <Tag size="sm" colorScheme={isValid ? 'white' : 'red'}>
              {turnAction.attackNumber}
            </Tag>
          )}

          {!turnAction.damageRider && button}
          <ActionBox
            name={name}
            attributes={attributes}
            level={turnLevel}
            showLevel={showLevel}
            activeWeaponMasteries={turnEngine.activeWeaponMasteryWeaponTypes}
          />
        </HStack>
      </WrappingTd>
      <Td>{toHitElement}</Td>
      {/* <Td>{toHitPercentString}%</Td> */}
      <WrappingTd>{damage}</WrappingTd>
      <DamageCell damageData={damageData[0]} />
    </Tr>
  )
}
