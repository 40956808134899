import { ActionParser } from './ActionParser'
import { Action } from '../CharacterJSON/Actions'
import { AttackAction } from '../AttackAction'
import { Utility } from '../../Common/Utility'
import { Range } from '../CharacterJSON/Range'
import { Dice } from '../Dice'
import { Activation } from '../CharacterJSON/Activation'

export class ActionParserCleric {
  static parse(parser: ActionParser, action: Action, attackActions: AttackAction[]): boolean {
    const character = parser.character

    if (character.classLevel('Cleric') === 0) return false

    const name = action.name

    if (name === 'Channel Divinity: Radiance of the Dawn') {
      const spellAttackRange = Range.makeSpellAttackRange(30)
      const attributes = {
        range: spellAttackRange,
        attackMod: 0,
        type: 'Spell',
        requiresSavingThrow: true,
        requiresAttackRoll: false,
        saveDcAbility: Utility.shortNameForAbilityID(action.saveStatId! - 1),
        damageTypes: ['radiant'],
        saveDcValue: parser.spellSaveDC
      }

      const dice = new Dice(action.dice)

      if (action.id === 9414166) {
        // 2024 Radiance of the Dawn deals 2d10+level damage
        dice.fixedValue = character.classLevel('Cleric')
      }
      const activation = new Activation(action.activation)
      const attack = new AttackAction(action.id, name, parser.spellAttackMod, dice, attributes, activation)
      attackActions.push(attack)
      return true
    }

    if (name === 'Channel Divinity: Sear Undead') {
      const spellAttackRange = Range.makeSpellAttackRange(30)
      const attributes = {
        range: spellAttackRange,
        attackMod: 0,
        type: 'Spell',
        requiresSavingThrow: true,
        requiresAttackRoll: false,
        saveDcAbility: Utility.shortNameForAbilityID(4),
        saveDcValue: parser.spellSaveDC
      }

      const dice = new Dice(action.dice)
      dice.diceCount = character.spellcastingAbilityModifier()
      const activation = new Activation(action.activation)
      const attack = new AttackAction(action.id, name, parser.spellAttackMod, dice, attributes, activation)
      attackActions.push(attack)
      return true
    }

    if (name === 'Channel Divinity: Divine Spark') {
      const spellAttackRange = Range.makeSpellAttackRange(30)
      const attributes = {
        range: spellAttackRange,
        attackMod: 0,
        type: 'Spell',
        requiresSavingThrow: true,
        requiresAttackRoll: false,
        saveDcAbility: Utility.shortNameForAbilityID(2),
        saveDcValue: parser.spellSaveDC
      }

      const dice = new Dice(action.dice)
      dice.diceCount = character.clericDivineSparkDieCount()
      dice.fixedValue = character.modifierForAbility('wisdom')
      const activation = new Activation(action.activation)
      const attack = new AttackAction(action.id, name, parser.spellAttackMod, dice, attributes, activation)
      attackActions.push(attack)
      return true
    }
    return false
  }
}
