import { Dictionary } from '../../Common/Types'
import { Range } from '../CharacterJSON//Range'
import { AttackAction } from '../AttackAction'
import { Activation } from '../CharacterJSON/Activation'
import { Dice } from '../Dice'
import { Utility } from '../../Common/Utility'
import { Creature } from '../CharacterJSON/Creature'
import { Spell } from '../Spell'
import { Character } from '../Character'
import { CustomizationValueMap } from '../CustomizationValues'
import { CharacterJSON } from '../CharacterJSON/CharacterJSON'
import { Weapon } from '../Weapon'
import * as ActionParsers from './ActionParsers'
import { WeaponProperty } from '../WeaponAttributes'

export class ActionParser {
  character: Character
  companionAttrs: Dictionary
  proficiencyBonus: number
  spellAttackMod: number
  spellcastingAbilityMod: number
  bonusAction: Activation
  action: Activation
  spellSaveDC: number
  wisMod: number

  constructor(character: Character) {
    this.character = character

    const meleeAttackRange = Range.makeWeaponRange(5)
    this.companionAttrs = { range: meleeAttackRange, type: 'Companion' }
    this.proficiencyBonus = character.proficiencyBonus()
    this.spellAttackMod = character.spellAttackModifier()
    this.spellcastingAbilityMod = character.spellcastingAbilityModifier()
    this.bonusAction = Activation.BonusAction()
    this.action = Activation.Action()
    this.spellSaveDC = character.spellSaveDC()
    this.wisMod = character.modifierForAbility('wisdom')
  }

  parse(
    characterData: CharacterJSON,
    weapons: Weapon[],
    creatures: Creature[],
    spells: Spell[],
    customizations: CustomizationValueMap,
    spellIDsToPrune: number[]
  ): AttackAction[] {
    const attackActions: AttackAction[] = []
    const fakeIDBaseList: number[] = [100000000, 1] // WHAT A NIGHTMARE HACK LOL

    const actions = characterData.actions

    // Creatures (note… Class features depend on this happening first - like accursed specter)
    creatures.forEach((creature) => {
      attackActions.push(...creature.attacks)
    })

    // Actions
    actions.race.forEach((action) => {
      ActionParsers.Racial.parse(this, action, attackActions)
    })

    actions.feat.forEach((action) => {
      ActionParsers.WeaponFeat.parse(this, action, weapons, attackActions)
    })

    actions.class.forEach((action) => {
      if (this.classParsers().find((parser) => parser.parse(this, action, attackActions))) return
    })

    // Traits
    characterData.race.racialTraits.forEach((trait) => {
      ActionParsers.RacialTrait.parse(this, trait, attackActions)
    })

    // Spells
    spells.forEach((spell) => {
      ActionParsers.Spell.parse(this, spell, spellIDsToPrune, fakeIDBaseList, attackActions)
    })

    // Weapons
    weapons.forEach((weapon) => {
      ActionParsers.Weapon.parse(this, weapon, attackActions)
    })

    ActionParsers.UnarmedStrike.createActions(this, actions.class, fakeIDBaseList, attackActions)
    ActionParsers.Weapon.createActions(this, weapons, fakeIDBaseList, attackActions)

    // Clean up
    this.renameAttackActions(attackActions, customizations)

    return attackActions
  }

  classParsers() {
    return [
      ActionParsers.Druid,
      ActionParsers.Monk,
      ActionParsers.Artificer,
      ActionParsers.Cleric,
      ActionParsers.Rogue,
      ActionParsers.Ranger,
      ActionParsers.Warlock,
      ActionParsers.Bard,
      ActionParsers.Barbarian,
      ActionParsers.Sorcerer
    ]
  }

  createSpellAttackWithSaveAction(id: number, action: Dictionary, range: Range, saveDcValue: number) {
    const saveDcAbility = Utility.shortNameForAbilityID(action.saveStatId - 1)
    const type = 'Spell Attack'
    const requiresSavingThrow = true
    const requiresAttackRoll = false
    const attributes = { range, type, requiresSavingThrow, requiresAttackRoll, saveDcAbility, saveDcValue }
    return new AttackAction(id, action.name, 0, new Dice(action.dice), attributes, new Activation(action.activation))
  }

  createAoeSpellAction(
    name: string,
    action: Dictionary,
    activation: Activation,
    saveDcValue: number,
    requiresSavingThrow: boolean = true
  ) {
    const range = Range.makeWeaponRange(parseInt(action.range.range))
    range.aoeSize = parseInt(action.range.aoeSize)
    range.aoeType = action.range.aoeType
    const saveDcAbility = requiresSavingThrow ? Utility.shortNameForAbilityID(action.saveStatId - 1) : null
    const type = 'Weapon'
    const requiresAttackRoll = false
    const attributes = { range, type, requiresSavingThrow, requiresAttackRoll, saveDcAbility, saveDcValue }
    return new AttackAction(parseInt(action.id), name, 0, new Dice(action.dice), attributes, activation)
  }

  thrownfinesseWeaponAttrs(range: Range): Dictionary {
    const propertyNames: WeaponProperty[] = ['Finesse', 'Thrown']
    return { range, type: 'Weapon', propertyNames }
  }

  createWeaponAttackAction(
    character: Character,
    name: string,
    action: Dictionary,
    range: number,
    diceOverride: Dice | undefined = undefined
  ): AttackAction {
    const { abilityModifierStatId, dice, id, activation } = action
    const attackRange = Range.makeWeaponRange(range)
    const abilityMod = character.modifierForAbilityIndex(parseInt(abilityModifierStatId) - 1)
    const toHit = character.proficiencyBonus() + abilityMod
    const damageDice = diceOverride ? diceOverride : new Dice(dice)
    damageDice.fixedValue += abilityMod
    const attributes = { range: attackRange, type: 'Weapon' }
    return new AttackAction(id, name, toHit, damageDice, attributes, new Activation(activation))
  }

  createUnarmedStrikeAttackAction(
    character: Character,
    name: string,
    activation: Activation,
    id: number,
    dice: Dice,
    modMultiplier: number = 1,
    dexOverride: boolean = false
  ): AttackAction {
    const strIndex = Utility.indexOfAbility('strength')
    const dexIndex = Utility.indexOfAbility('dexterity')
    let abilityModifierStatId = dexOverride ? dexIndex : strIndex
    if (character.classLevel('Monk') > 0 && character.dexterity() > character.strength()) {
      abilityModifierStatId = Utility.indexOfAbility('dexterity')
    }

    const attackRange = Range.makeWeaponRange(5) // TODO: Racial modifier for reach would go here, if we ever care about reach
    const abilityMod = character.modifierForAbilityIndex(abilityModifierStatId)
    const toHit = character.proficiencyBonus() + abilityMod
    const damageDice = new Dice(dice)
    damageDice.fixedValue += abilityMod * modMultiplier
    const attributes = { range: attackRange, type: 'Weapon', subType: 'unarmed' }
    const attack = new AttackAction(id, name, toHit, damageDice, attributes, new Activation(activation))
    attack.attributes.subType = 'unarmed'
    return attack
  }

  parseCreatureAttackActions(creatures: Creature[], attackActions: AttackAction[]) {
    creatures.forEach((creature) => attackActions.push(...creature.attacks))
  }

  renameAttackActions(attackActions: AttackAction[], customizations: CustomizationValueMap) {
    attackActions.forEach((action) => {
      const customization = customizations[action.id]
      if (customization?.name) action.name = customization.name
    })
  }
}
