import React, { useState } from 'react'

import { Text, VStack, Input, Table, Tr, Td, Th, Thead, Tbody, HStack } from '@chakra-ui/react'
import { DiceCalc } from '../Dice/DiceCalc'
import { Dictionary, Dispatcher } from '../Common/Types'
import { InputChangeEvent, InputHandler } from '../Common/Types'
import { DiceMap, average, extractHitValues, total } from '../Dice/DiceMath'

// function FixedNumberInput({ defaultValue }: { defaultValue: number }) {
//   return (
//     <NumberInput min={0} max={99} defaultValue={defaultValue} size="md" width="2rem" precision={0} py={0}>
//       <NumberInputField px={1.5} py={0} textAlign={'right'} />
//     </NumberInput>
//   )
// }
// function DieCountInput({ text, defaultValue }: { text: string; defaultValue: number }) {
//   return (
//     <HStack>
//       <FixedNumberInput defaultValue={defaultValue} />
//       <Text fontSize="md">{text}</Text>
//     </HStack>
//   )
// }

// export function WrappingTd({ children }: { children: React.ReactNode }) {

function InputField({ placeholder, onChange }: { placeholder: string; onChange: InputHandler }) {
  return (
    <Input
      placeholder={placeholder}
      size="md"
      bg="transparent"
      borderColor="gray.600"
      color="white"
      width={'40rem'}
      _hover={{ borderColor: 'gray.500' }}
      _focus={{ borderColor: 'blue.300' }}
      onChange={onChange}
    />
  )
}

function CalculatingInput({
  placeholder,
  state
}: {
  placeholder: string
  state: [Dictionary | undefined, Dispatcher<Dictionary | undefined>]
}) {
  const [, setEvalaution] = state
  const [, setParseError] = useState<unknown | undefined>(undefined)

  const handleInputChange = (e: InputChangeEvent) => {
    const value = e.target.value

    try {
      if (value) {
        const diceEval = DiceCalc.evaluate(value)
        setParseError(undefined)
        setEvalaution(diceEval)
      } else {
        setEvalaution(undefined)
      }
    } catch (error) {
      setParseError(error)
      setEvalaution(undefined)
    }
  }

  return <InputField placeholder={placeholder} onChange={handleInputChange} />
}

function CalculatingDamageInputRows({
  title,
  placeholder,
  state
}: {
  title: string
  placeholder: string
  state: [string, Dispatcher<string>]
}) {
  const [, setInputValue] = state
  const [average, setAverage] = useState<number | undefined>(0)
  const [, setParseError] = useState<unknown | undefined>(undefined)

  const handleInputChange = (e: InputChangeEvent) => {
    const value = e.target.value
    setInputValue(value)

    try {
      if (value) {
        const diceEval = DiceCalc.evaluate(value)
        setParseError(undefined)
        setAverage(diceEval.average())
      } else {
        setAverage(undefined)
      }
    } catch (error) {
      setParseError(error)
      setAverage(undefined)
    }
  }

  return (
    <Tr>
      <Td>
        <Text fontSize="md">{title}</Text>
      </Td>
      <Td>
        <InputField placeholder={placeholder} onChange={handleInputChange} />
      </Td>
      <Td>
        <Text fontSize="md" width={'3rem'}>
          {average?.toFixed(Number.isInteger(average) ? 0 : 1)}
        </Text>
      </Td>
    </Tr>
  )
}

function NumberInputRow({
  title,
  placeholder,
  state
}: {
  title: string
  placeholder: string
  state: [string, Dispatcher<string>]
}) {
  const [, setInputValue] = state
  const handleInputChange = (e: InputChangeEvent) => {
    setInputValue(e.target.value)
  }

  return (
    <Tr>
      <Td>
        <Text fontSize="md">{title}</Text>
      </Td>
      <Td>
        <InputField placeholder={placeholder} onChange={handleInputChange} />
      </Td>
      <Td />
    </Tr>
  )
}

export function RawCalculatorTable() {
  const [damageOnHit, setDamageOnHit] = useState<string>('')
  const [toHit, setToHit] = useState<string>('')
  const [targetAC, setTargetAC] = useState<string>('')
  const [critsOn, setCritsOn] = useState<string>('')
  const [attackCount, setAttackCount] = useState<string>('')

  const header = (
    <Thead>
      <Tr>
        <Th>Input</Th>
        <Th>Value</Th>
        <Th>Average</Th>
      </Tr>
    </Thead>
  )

  return (
    <Table size="sm" width={'0'} variant={'simple'}>
      {header}
      <Tbody>
        <CalculatingDamageInputRows title="Damage on Hit" placeholder="1d6+2" state={[damageOnHit, setDamageOnHit]} />
        <NumberInputRow title="To Hit" placeholder="7" state={[toHit, setToHit]} />
        <NumberInputRow title="Target AC" placeholder="12" state={[targetAC, setTargetAC]} />
        <NumberInputRow title="Crits On" placeholder="20" state={[critsOn, setCritsOn]} />
        <NumberInputRow title="Attack Count" placeholder="2" state={[attackCount, setAttackCount]} />
      </Tbody>
    </Table>
  )
}

export function RawCalculatorPage() {
  const [evaluation, setEvaluation] = useState<Dictionary | undefined>(undefined)

  const setNewEvaluation = (newEval: Dictionary | undefined) => {
    setEvaluation(newEval)
  }

  const allValues: DiceMap = evaluation ? evaluation : {}
  const critValues: DiceMap = evaluation ? evaluation.private.critValues || {} : {}
  const hitValues: DiceMap = evaluation ? extractHitValues(allValues, critValues) : {}

  return (
    <VStack pt={4} spacing={4}>
      <CalculatingInput placeholder="(d20 + 6 AC 10) * (1d6+2) crit (2d6+2)" state={[evaluation, setNewEvaluation]} />
      <HStack>
        <Text height="2rem">{evaluation ? `Average: ${evaluation.average()}` : ' '}</Text>
        <Text height="2rem">{evaluation ? `Chance to miss: ${(100 * evaluation[0]) / total(allValues)}%` : ' '}</Text>
      </HStack>
      <Table size="md" variant="simple" width={'40rem'}>
        <Thead>
          <Td>Damage</Td>
          <Td>All</Td>
          <Td>Hit</Td>
          <Td>Crit</Td>
        </Thead>
        <Tbody>
          {Object.entries(allValues).map(([face]) => (
            <React.Fragment key={face}>
              <Tr>
                <Td>{face}</Td>
                <Td>{allValues[Number(face)] || 0}</Td>
                <Td>{hitValues[Number(face)] || 0}</Td>
                <Td>{critValues[Number(face)] || 0}</Td>
              </Tr>
            </React.Fragment>
          ))}
          <Tr fontWeight="bold">
            <Td>Total</Td>
            <Td>{total(allValues)}</Td>
            <Td>{total(hitValues)}</Td>
            <Td>{total(critValues)}</Td>
          </Tr>
          <Tr fontWeight="bold">
            <Td>Average</Td>
            <Td>{average(allValues)}</Td>
            <Td>{average(hitValues)}</Td>
            <Td>{average(critValues)}</Td>
          </Tr>
        </Tbody>
      </Table>
    </VStack>
  )
}

// Inputs
// Attack Bonus (11)
// Target AC (15)
// Damage
//  0d4 + 0d6 + 0d8 + 1d10 + 1d12 + X
// Crits on X
// Number of Attacks 1
// Advanced Options
//  Elven Accuracy
//  Lucky (Halfling)
//  Great Weapon Fighter Style
//  Elemental Adept
//  GWM Crit Bonus
//  Half damage on miss
//  Min damage on miss

// TODO: How do we do all the masteries?

// Bonus on first hit (Sneak Attack, Divine Strike, etc)
//  0d4 + 0d6 + 0d8 + 1d10 + 1d12 + X

// Bonus Dice to HIt (Bless, Inspiration, etc)
//  0d4 + 0d6 + 0d8 + 1d10 + 1d12 +

// Penalty Dice to Hit (Bane, Synaptic Static, etc)
//  0d4 + 0d6 + 0d8 + 1d10 + 1d12

// Bonus on Crit (Brutal Criticals, Half Orc, etc)

//Bonus Action Attacks
// Attack Bonus  | Number of Attacks
// Damage
//  0d4 + 0d6 + 0d8 + 1d10 + 1d12 + X
// Bonus on Crit
//  0d4 + 0d6 + 0d8 + 1d10 + 1d12 + X
// Bonus Dice to Hit (Bless)
//  0d4 + 0d6 + 0d8 + 1d10 + 1d12
// Penalty Dice to Hit (Bane)
//  0d4 + 0d6 + 0d8 + 1d10 + 1d12
// Advantage

// Outputs (Normal / Adv / DIS)
// Chance to Hit %
// Adverage DPR
// Damage Per Hit
// Damage Per Crit
// Critical Hit Chance
// Chance of at least one hit
// Chance of at least one crit
// -5/+10 DPR
// -5/+10 Hit Difference
// -5/+10 Crit Chance to HIt
// -5/+10 Chance of at least one hit
