import { ActionParser } from './ActionParser'
import { Action } from '../CharacterJSON/Actions'
import { AttackAction } from '../AttackAction'

import { Range } from '../CharacterJSON/Range'
import { Dice } from '../Dice'

export class ActionParserArtificer {
  static parse(parser: ActionParser, action: Action, attackActions: AttackAction[]): boolean {
    const character = parser.character
    if (character.classLevel('Artificer') === 0) return false

    const name = action.name

    if (['Eldritch Cannon: Flamethrower', 'Detonate Eldritch Cannon', 'Drake’s Breath'].includes(name)) {
      const attack = parser.createAoeSpellAction(name, action, parser.bonusAction, parser.spellSaveDC)
      attackActions.push(attack)
      return true
    }

    if (name === 'Guardian Armor: Thunder Gauntlets' || name === 'Guardian Armor: Thunder Gauntlets (STR)') {
      const attack = parser.createWeaponAttackAction(character, name, action, 5)
      attackActions.push(attack)
      return true
    }

    if (name === 'Infiltrator Armor: Lightning Launcher' || name === 'Infiltrator Armor: Lightning Launcher (DEX)') {
      const attack = parser.createWeaponAttackAction(character, name, action, 90)
      attack.attributes.range = Range.makeWeaponRange(90, 300)
      attackActions.push(attack)
      return true
    }

    if (name === 'Steel Defender') {
      const dice = Dice.Create(1, 8, parser.proficiencyBonus) // TODO - scale with level
      const newName = 'Force-Empowered Rend'
      const sam = parser.spellAttackMod
      const attributes = { range: Range.makeWeaponRange(5), type: 'Spell Attack' }
      const attack = new AttackAction(action.id, newName, sam, dice, attributes, parser.bonusAction)

      attackActions.push(attack)
      return true
    }

    if (name === 'Eldritch Cannon: Force Ballista') {
      const attributes = {
        range: Range.makeSpellAttackRange(action.range.range),
        type: 'Spell Attack',
        requiresAttackRoll: true
      }
      const abilityMod = character.modifierForAbilityIndex(action.abilityModifierStatId! - 1)
      const dice = new Dice(action.dice)
      dice.fixedValue += abilityMod
      const attack = new AttackAction(action.id, name, parser.spellAttackMod, dice, attributes, parser.bonusAction)
      attackActions.push(attack)
      return true
    }

    return false
  }
}
