import { ActionParser } from './ActionParser'
import { AttackAction } from '../AttackAction'
import { Action } from '../CharacterJSON/Actions'

export class ActionParserBarbarian {
  static parse(parser: ActionParser, action: Action, attackActions: AttackAction[]) {
    const name = action.name
    const character = parser.character
    if (character.classLevel('Barbarian') === 0) return false

    if (name.startsWith('Form of the Beast:')) {
      const reachLookup: Record<string, number> = {
        'Form of the Beast: Bite': 5,
        'Form of the Beast: Claws': 5, // TODO - add a bonus attack if this is selected as a feature!
        'Form of the Beast: Tail': 10
      }

      const reach = reachLookup[name]
      if (reach !== undefined) {
        const attack = parser.createWeaponAttackAction(character, name, action, reach)
        attackActions.push(attack)
        return true
      }
    }

    return false
  }
}
