import { DamageType } from '../../Common/Types'
import { ActivationType } from '../CharacterJSON/Activation'
import { Dice } from '../Dice'
import { FeatureSource } from '../FeatureSource'
import { FeatureParserBase } from './FeatureParserBase'

export class FeatureParserFeat {
  static parse(parser: FeatureParserBase): boolean {
    const { feature, action, character } = parser
    const { only, effects, name } = feature
    if (feature.featureSource !== FeatureSource.Feat) return false

    // 2024 Epic Boons
    if (name === 'Boon of the Night Spirit') {
      only.oncePerTurn = true
      only.attackRoll = true
      effects.advantage = true
      return true
    }

    if (name === 'Boon of Fate') {
      only.attackRoll = true
      only.oncePerTurn = true
      effects.additionalToHitDice = Dice.Create(2, 4)
      return true
    }

    if (name === 'Boon of Irresistible Offense') {
      // DDB isn't handing down the stat, so just choose whichever is higher (dex or str)
      const str = character.scoreForAbility('strength')
      const dex = character.scoreForAbility('dexterity')
      const bonus = Math.max(str, dex)
      only.attackRoll = true
      effects.additionalDamageOnCritDice = Dice.flatAmountDie(bonus)
      feature.notes = `+${bonus} crit dmg when you roll a 20 on an attack roll`
      return true
    }

    if (name === 'Boon of Combat Prowess') {
      only.attackRoll = true
      only.oncePerTurn = true
      effects.autoHit = true
      feature.notes = 'Your next attack is an automatic hit'
      return true
    }

    if (name === 'Crossbow Expert' && action.id === 1789126) {
      // 2024
      only.weaponProperties = ['Light']
      only.offHand = true
      only.crossbow = true
      effects.addWeaponStatModifier = true
      feature.notes = 'Add ability mod to off-hand crossbow with Light property'
      return true
    }

    if ((name === 'Grappler' && action.id) === 1789147) {
      // 2024
      only.meleeOrUnarmed = true
      effects.nextAttacksHaveAdvantage = true
      feature.notes = 'Unarmed attack can both damage and grapple'
      return true
    }

    if (name === 'Mounted Combatant') {
      only.weapon = true
      effects.advantage = true
      feature.name = 'Mounted Strike'
      feature.notes = 'Advantage on attack rolls against unmounted creatures'
      return true
    }

    if (name === 'Mounted Strike') {
      return true
    }

    if (name === 'Tavern Brawler' && (action.id === 1789202 || action.id === '1789202')) {
      // Check the ID to make sure we don't apply this to 2014 Tavern Brawler
      only.unarmed = true
      effects.rerollDamageDiceThreshold = 1
      feature.defaultEnabled = true
      feature.notes = 'Reroll unarmed strike damage dice'
      return true
    }

    // 2014

    if (name === 'Piercer') {
      only.weapon = true
      only.damageTypes = ['Piercing']
      only.oncePerTurn = true
      effects.rerollAllDamageDiceOnHit = true
      feature.defaultEnabled = true

      if (action.id !== 1789175 && action.id !== '1789175') {
        feature.name = `${feature.name} (2014)`
      }

      return true
    }

    if (name === 'Elemental Adept') {
      if (action.option === undefined) {
        return false
      }
      const elementalType = action.option.name
      if (elementalType === undefined) {
        // If they chose the feat but not the element yet
        return false
      }

      only.spell = true
      only.damageTypes = [elementalType.toLowerCase()]
      effects.minimumDamageDieRoll = 2
      feature.defaultEnabled = true
      feature.name = `${feature.name} (${elementalType})`
      return true
    }

    if (name.startsWith('Elemental Adept (')) {
      const match = name.match(/\((\w+)\)/)
      if (match) {
        only.spell = true
        only.damageTypes = [match[1].toLowerCase() as DamageType]
        effects.minimumDamageDieRoll = 2
        feature.defaultEnabled = true
        feature.name = `${feature.name} (2014)`
        return true
      }
    }

    if (name === 'Savage Attacker') {
      only.meleeWeapon = true
      effects.rerollAllDamageDiceOnHit = true
      feature.defaultEnabled = true
      only.oncePerTurn = true
      if (action.id !== 1789183 && action.id !== '1789183') {
        feature.name = `${feature.name} (2014)`
      }
      return true
    }

    if (name === 'Flames of Phlegethos') {
      only.spell = true
      only.damageTypes = ['fire']
      effects.rerollDamageDiceThreshold = 1
      feature.defaultEnabled = true
      feature.name = `${feature.name} (2014)`
      feature.notes = 'Reroll 1s on fire spell damage'
      return true
    }

    if (name === 'Great Weapon Master') {
      only.meleeWeapon = true
      only.weaponProperties = ['Heavy']

      if (action.id === 1789149 || action.id === '1789149') {
        // 2024 version
        only.activationType = ActivationType.ACTION
        effects.additionalDamageOnHitDice = Dice.flatAmountDie(character.proficiencyBonus())
        feature.defaultEnabled = true
      } else {
        effects.additionalToHitDice = Dice.flatAmountDie(-5)
        effects.additionalDamageOnHitDice = Dice.flatAmountDie(10)

        feature.name = `${feature.name} (2014)`
        feature.notes = '-5 to hit, +10 dmg with heavy weapon'
      }
      return true
    }

    if (name === 'Sharpshooter' && (action.id === 42 || action.id === '42')) {
      only.weaponProperties = ['Range', 'Thrown']
      effects.additionalToHitDice = Dice.flatAmountDie(-5)
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(10)
      feature.name = `${feature.name} (2014)`
      feature.notes = '-5 to hit, +10 dmg with ranged weapon'
      return true
    }

    if (name === 'Elven Accuracy') {
      only.attackRoll = true
      effects.rerollOnAdvantage = true
      feature.defaultEnabled = true
      feature.name = `${feature.name} (2014)`
      feature.notes = 'When you have advantage, you can reroll one die'
      return true
    }

    if (name === 'Lucky') {
      // TODO can only be used 3 times a day (2024 - PB times)
      only.attackRoll = true
      effects.advantage = true
      feature.usesLimitedResource = true
      if (action.id !== 1789160 && action.id !== '1789160') {
        feature.name = `${feature.name} (2014)`
      }
      return true
    }

    if (name === 'Squire of Solamnia') {
      feature.name = 'Squire of Solamnia: Precise Strike'
      only.weapon = true
      effects.additionalToHitDice = Dice.Create(1, 8)
      effects.advantage = true
      only.oncePerTurn = true
      feature.usesLimitedResource = true
      feature.notes = 'Next weapon attack is with advantage and +1d8 dmg'
      feature.name = `${feature.name} (2014)`
      return true
    }

    if (name === 'Charger') {
      only.meleeOrUnarmed = true
      only.oncePerTurn = true

      if (action.id === 1789121 || action.id === '1789121') {
        // 2024 version
        effects.additionalDamageOnHitDice = Dice.Create(1, 8)
      } else {
        effects.additionalDamageOnHitDice = Dice.flatAmountDie(5)
        feature.name = `${feature.name} (2014)`
      }

      return true
    }
    return false
  }
}
