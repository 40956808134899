import { ActionParser } from './ActionParser'
import { Action } from '../CharacterJSON/Actions'
import { AttackAction } from '../AttackAction'
import { Utility } from '../../Common/Utility'
import { Range } from '../CharacterJSON/Range'
import { Dice } from '../Dice'
import { Activation } from '../CharacterJSON/Activation'

export class ActionParserDruid {
  static parse(parser: ActionParser, action: Action, attackActions: AttackAction[]): boolean {
    const character = parser.character

    if (character.classLevel('Druid') === 0) return false

    const name = action.name

    if (name === 'Summon Wildfire Spirit: Command') {
      const spellAttackRange = Range.makeSpellAttackRange(15)
      const attributes = {
        range: spellAttackRange,
        attackMod: 0,
        type: 'Spell',
        requiresSavingThrow: true,
        requiresAttackRoll: false,
        saveDcAbility: Utility.shortNameForAbilityID(1),
        saveDcValue: parser.spellSaveDC
      }
      const dice = Dice.Create(1, 6, parser.proficiencyBonus)
      const ftName = 'Fiery Teleportation'
      const attack = new AttackAction(action.id, ftName, parser.spellAttackMod, dice, attributes, parser.bonusAction)
      attackActions.push(attack)
      return true
    }

    if (name === 'Summon Wildfire Spirit') {
      const rangedAttackRange = Range.makeWeaponRange(60)
      const fsAttrs = { range: rangedAttackRange, type: 'Weapon' }
      const fsDice = Dice.Create(1, 6, parser.proficiencyBonus)
      const fsName = 'Wildfire Spirit: Flame Seed'
      const attack = new AttackAction(action.id, fsName, parser.spellAttackMod, fsDice, fsAttrs, parser.bonusAction)
      attackActions.push(attack)
      return true
    }

    if (name === 'Spreading Spores') {
      const sporeDamage = character.sporeDamageDie()
      const range = Range.makeSpellAttackRange(action.range.range)
      const attributes = {
        range: range,
        type: 'Spell',
        requiresSavingThrow: false,
        requiresAttackRoll: false
      }
      const attack = new AttackAction(
        action.id,
        name,
        parser.spellAttackMod,
        sporeDamage,
        attributes,
        parser.bonusAction
      )
      attackActions.push(attack)
      return true
    }

    if (name === 'Cauterizing Flames') {
      const spellAttackRange = Range.makeSpellAttackRange(0)
      const attributes = {
        range: spellAttackRange,
        attackMod: 0,
        type: 'Spell',
        requiresSavingThrow: false,
        requiresAttackRoll: false
      }

      const dice = Dice.Create(2, 10, parser.wisMod)
      const attack = new AttackAction(action.id, name, parser.spellAttackMod, dice, attributes, parser.bonusAction)
      attackActions.push(attack)
      return true
    }

    if (name === 'Fungal Infestation') {
      const toHit = 3
      const dice = Dice.Create(1, 6, 1)
      const attack = new AttackAction(
        action.id,
        'Fungal Zombie Slam',
        toHit,
        dice,
        parser.companionAttrs,
        parser.bonusAction
      )
      attackActions.push(attack)
      return true
    }

    if (name === 'Land’s Aid: Damage') {
      const attributes = {
        range: new Range(action.range),
        type: 'Spell Attack',
        requiresSavingThrow: true,
        requiresAttackRoll: false,
        saveDcAbility: Utility.shortNameForAbilityID(action.saveStatId ? action.saveStatId - 1 : 2),
        saveDcValue: parser.spellSaveDC
      }

      const dice = new Dice(action.dice)
      const activation = new Activation(action.activation)
      const attack = new AttackAction(action.id, name, parser.spellAttackMod, dice, attributes, activation)
      attackActions.push(attack)
      return true
    }

    if (name === 'Starry Form: Archer' || name === 'Archer: Luminous Arrow') {
      // 2024 is just "Assume Starry Form? - no more archer in json

      const spellAttackRange = action.range.range
        ? Range.makeSpellAttackRange(action.range.range)
        : Range.makeSpellAttackRange(150)
      const attributes = { range: spellAttackRange, type: 'Spell Attack' }
      const druidLevel = character.classLevel('Druid')
      const dice = Dice.Create(druidLevel >= 10 ? 2 : 1, 8, parser.spellcastingAbilityMod)
      const displayName = 'Archer: Luminous Arrow'
      const attack = new AttackAction(
        action.id,
        displayName,
        parser.spellAttackMod,
        dice,
        attributes,
        parser.bonusAction
      )
      attackActions.push(attack)
      return true
    }

    if (name === 'Storm Aura: Sea') {
      // TODO - Look at radiance of the dawn for a spell version of this
      // When this effect is activated, you can choose one other creature you can see in your aura.
      // The target must make a Dexterity saving throw.The target takes 1d6 lightning damage on a failed save,
      // or half as much damage on a successful one.The damage increases when you reach certain levels in this class,
      // increasing to 2d6 at 10th level, 3d6 at 15th level, and 4d6 at 20th level.
      //   const barbarianLevel = this.classLevel('Barbarian')
      //   const diceCount = barbarianLevel >= 10 ? 1 + Math.floor((barbarianLevel - 5) / 5) : 1
      //   const dice = Dice.Create(diceCount, 6)
      //   attack = new AttackAction(action.id, )
      return true
    }

    if (name === 'Manifest Wrath of the Sea') {
      // TODO this isn't a 'save half' one…

      const spellAttackRange = Range.makeSpellAttackRange(30)
      const attributes = {
        range: spellAttackRange,
        attackMod: 0,
        type: 'Spell',
        requiresSavingThrow: true,
        requiresAttackRoll: false,
        saveDcAbility: Utility.shortNameForAbilityID(2),
        saveDcValue: parser.spellSaveDC
      }

      const dice = Dice.Create(parser.wisMod, 6)
      const activation = new Activation(action.activation)
      const attack = new AttackAction(action.id, name, parser.spellAttackMod, dice, attributes, activation)
      attackActions.push(attack)
      return true
    }

    return false
  }
}
