import { Dice } from '../Dice'
import { FeatureParserBase } from './FeatureParserBase'

export class FeatureParserWizard {
  static parse(parser: FeatureParserBase) {
    const thisClass = 'Wizard'
    if (!parser.validate(thisClass)) return false
    const { name, only, feature, effects, character, isSubclassFeature } = parser
    const classLevel = parser.classLevel(thisClass)

    if (name === 'Empowered Evocation') {
      const intMod = character.modifierForAbility('intelligence')
      only.spell = true
      only.oncePerTurn = true
      only.spellSchool = 'Evocation'
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(intMod)
      feature.notes = `+${effects.additionalDamageOnHitDice.diceString()} dmg to next evocation spell`
      return true
    }

    if (name === 'Potent Cantrip') {
      only.cantrip = true
      effects.potentCantrip = true
      feature.notes = `Cantrips deal half damage on save and miss`
      return true
    }

    if (name === 'Song of Victory') {
      const intMod = character.modifierForAbility('intelligence')
      only.meleeWeapon = true
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(intMod)
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'Power Surge') {
      if (isSubclassFeature) return feature.skip()
      only.spell = true
      feature.usesLimitedResource = true

      effects.additionalDamageOnHitDice = Dice.flatAmountDie(Math.floor(classLevel / 2))
      return true
    }

    if (name === 'Overchannel') {
      if (isSubclassFeature) return feature.skip()
      only.spell = true
      only.maxSpellLevel = 5
      feature.usesLimitedResource = true
      effects.maxDamage = true
      feature.notes = 'Maximum spell damage for level 1-5 spells'
      return true
    }

    if (name === 'Undead Thralls') {
      if (isSubclassFeature) return feature.skip()

      only.appliesToCompanionAttacks = true
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(character.proficiencyBonus())
      feature.notes = `+${effects.additionalDamageOnHitDice!.diceString()} dmg to creature attacks`
      return true
    }

    const nonBuffActions = [
      '12: Ability Score Improvement',
      '16: Ability Score Improvement',
      '8: Ability Score Improvement',
      'Ability Score Improvement',
      'Abjuration Savant',
      'Arcane Deflection',
      'Arcane Recovery',
      'Arcane Tradition',
      'Arcane Ward',
      'Arcane Ward: Hit Points',
      'Cantrip Formulas',
      'Core Wizard Traits',
      'Deflecting Shroud',
      'Durable Magic',
      'Epic Boon',
      'Equipment',
      'Evocation Savant',
      'Hit Points',
      'Memorize Spell',
      'Proficiencies',
      'Projected Ward',
      'Ritual Adept',
      'Scholar',
      'Sculpt Spells',
      'Signature Spells',
      'Spell Breaker',
      'Spell Mastery',
      'Spell Resistance',
      'Spellcasting',
      'Tactical Wit',
      'Wizard Subclass'
    ]

    if (nonBuffActions.includes(name)) return feature.skip()
    return false
  }
}
