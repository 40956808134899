import { Dice } from '../Dice'

import { FeatureParserBase } from './FeatureParserBase'

export class FeatureParserBarbarian {
  static parse(parser: FeatureParserBase) {
    const thisClass = 'Barbarian'
    if (!parser.validate(thisClass)) return false
    const { name, only, action, feature, effects, character, requiredLevel, isSubclassFeature } = parser
    const classLevel = parser.classLevel(thisClass)
    const is2024Class = parser.is2024Class(thisClass)

    if (classLevel === 0) return false
    if (feature.skipped()) return false
    if (requiredLevel && classLevel < requiredLevel) return feature.skip()

    if (name === 'Rage (Enter)') {
      const damageBonus: number = character.rageBonusDamage()
      only.attackRoll = true
      only.weaponOrUnarmed = true // TODO: technically strength based attack rolls only
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(damageBonus)
      effects.raging = true
      feature.usesLimitedResource = true
      feature.name = 'Rage'
      return true
    }

    if (name === 'Frenzy') {
      const rageBonus: number = character.rageBonusDamage()
      only.meleeOrUnarmed = true
      only.oncePerTurn = true
      only.raging = true // TODO implement
      feature.usesLimitedResource = true
      effects.additionalDamageOnHitDice = Dice.Create(rageBonus, 6)
      feature.notes = `+${effects.additionalDamageOnHitDice.diceString()} dmg next melee attack while raging`
      return true
    }

    if (name === 'Reckless Attack') {
      only.meleeOrUnarmed = true
      effects.advantage = true
      return true
    }

    if (name === 'Brutal Critical') {
      const levelDamageMap: Record<number, number> = { 9: 1, 13: 2, 17: 3 }
      for (const level in levelDamageMap) {
        const lvlInt = parseInt(level)
        if (classLevel >= lvlInt && classLevel < lvlInt + 4 && requiredLevel === lvlInt) {
          effects.additionalDamageDiceOnCrit = levelDamageMap[lvlInt]
          feature.defaultEnabled = true
          return true
        }
      }

      return feature.skip()
    }

    if (name === 'Totemic Attunement - Tiger') {
      effects.extraAttackThisTurn = true
      feature.notes = 'Extra bonus action melee attack'
      return true
    }

    if (name === 'Divine Fury' && is2024Class) {
      only.raging = true
      only.meleeOrUnarmed = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(1, 6, Math.floor(classLevel / 2))
      return true
    }

    if (name.startsWith('Brutal Strike:')) {
      only.featureNameEnabled = 'Reckless Attack'
      only.meleeOrUnarmed = true // TODO: Technically any strength based attack rolls
      only.oncePerTurn = true
      effects.forgoAdvantageNextAttack = true
      effects.additionalDamageOnHitDice = Dice.Create(classLevel >= 17 ? 2 : 1, 10)
      feature.notes = `Forgo adv while reckless for +${effects.additionalDamageOnHitDice.diceString()} dmg next attack`
      return true
    }

    if (name === 'Giant’s Havoc: Crushing Throw') {
      const damageBonus: number = character.rageBonusDamage()
      only.weapon = true
      only.weaponProperties = ['Thrown']
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(damageBonus)
      feature.name = 'Crushing Throw'
      return true
    }

    if (name === 'Giant’s Havoc: Giant Stature') {
      effects.damageMultiplier = 2 // TODO this sould be double weapon dice, not double damage
      effects.additionalMeleeRange = 10
      feature.name = 'Giant Stature'
      feature.notes = 'Grow Large and (todo) increase melee range by 5ft'
      return true
    }

    if (name === 'Form of the Beast: Claws') {
      only.weapon = true
      only.oncePerTurn = true
      effects.extraAttackThisAction = true
      feature.notes = 'Gain 1 additional claw attack if you attack with a claw'
      return true
    }

    if (name === 'Bolstering Magic') {
      effects.additionalDamageOnHitDice = Dice.Create(1, 3)
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'Rage') {
      if (isSubclassFeature) return feature.skip()
      const damageBonus: number = character.rageBonusDamage()
      only.meleeOrUnarmed = true
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(damageBonus)
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'Elemental Cleaver') {
      if (isSubclassFeature) return feature.skip()
      const barbarianLevel = character.classLevel('Barbarian')

      // TODO: Melee or thrown… but now a bow or crossbow, similar to twoHandedOrVersatileOnly
      only.weapon = true
      effects.additionalDamageOnHitDice = Dice.Create(barbarianLevel >= 14 ? 2 : 1, 6)
      return true
    }

    if (name === 'Frenzy Attack') {
      if (isSubclassFeature) return feature.skip()
      only.weapon = true
      effects.extraBonusAttacksThisAction = 1
      return true
    }

    if (feature.name === 'Divine Fury') {
      if (isSubclassFeature) return feature.skip()
      feature.usesLimitedResource = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = new Dice(action.dice)
      only.weapon = true
      return true
    }

    if (name === 'Infectious Fury') {
      if (isSubclassFeature) return feature.skip()

      feature.usesLimitedResource = true
      effects.additionalDamageOnHitDice = new Dice(action.dice)
      return true
    }

    const nonBuffActions = [
      '10: Weapon Mastery',
      '4: Weapon Mastery',
      'Armor Spike Attack',
      'Armor Spike Grapple',
      'Barbarian Subclass',
      'Battering Roots',
      'Battlerager Armor',
      'Battlerager Charge',
      'Branches of the Tree',
      'Brutal Strike',
      'Core Barbarian Traits',
      'Danger Sense',
      'Extra Attack',
      'Fanatical Focus',
      'Fast Movement',
      'Feral Instinct',
      'Improved Brutal Strike',
      'Indomitable Might',
      'Instinctive Pounce',
      'Intimidating Presence',
      'Mindless Rage',
      'Persistent Rage',
      'Primal Champion',
      'Primal Knowledge',
      'Primal Path',
      'Push',
      'Rage (Instinctive Pounce)',
      'Rage of the Gods',
      'Rage: Regain Expended Uses',
      'Rage: Relentless Rage',
      'Rage: Primal Knowledge',
      'Reckless Abandon',
      'Relentless Rage',
      'Restriction: Dwarves Only',
      'Retaliation',
      'Spiked Retribution',
      'Topple',
      'Travel along the Tree',
      'Unarmored Defense',
      'Vitality of the Tree',
      'Warrior of the Gods',
      'Warrior of the Gods: Expend Dice',
      'Weapon Mastery',
      'Zealous Presence'
    ]

    if (nonBuffActions.includes(name)) return feature.skip()
    return false
  }
}
