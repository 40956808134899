import { ActionParser } from './ActionParser'
import { AttackAction } from '../AttackAction'
import { Utility } from '../../Common/Utility'
import { Range } from '../CharacterJSON/Range'
import { Dice } from '../Dice'
import { Activation } from '../CharacterJSON/Activation'
import { Spell } from '../Spell'

export class ActionParserSpell {
  static parse(
    parser: ActionParser,
    spell: Spell,
    spellIDsToPrune: number[],
    fakeIDBaseList: number[],
    attackActions: AttackAction[]
  ) {
    const ba = parser.bonusAction
    const character = parser.character

    const name = spell.name
    let spellAction = undefined
    let spellIdToPrune: number | undefined = spell.id
    if (name === 'Spiritual Weapon') {
      const toHit = character.spellAttackModifier()
      const abilityMod = character.spellcastingAbilityModifier()
      const maxSpellLevel = character.highestLevelSpellSlot()
      const diceCount = Math.floor(maxSpellLevel / 2)
      const dice = Dice.Create(diceCount, 8, abilityMod)
      const attributes = { range: spell.range, type: 'Spell Attack' }
      spellAction = new AttackAction(
        fakeIDBaseList[0] + fakeIDBaseList[1]++,
        spell.displayName,
        toHit,
        dice,
        attributes,
        ba
      )
    } else if (name === 'Shadow Blade') {
      const maxLevel = Math.min(character.highestLevelSpellSlot(), 7)
      const dieCount = 2 + Math.floor((maxLevel - 1) / 2)
      spellAction = this.createShadowBlades(parser, spell, Dice.Create(dieCount, 8), parser.action)
    } else if (name === 'Magic Stone') {
      spellAction = spell.attackAction(character)
      spellAction.attributes.range = Range.makeWeaponRange(60)
      spellAction.activation = Activation.Action()
    } else if (name === 'Shillelagh') {
      spellAction = spell.attackAction(character)
      spellAction.activation = Activation.Action()
    } else if (name === 'Flame Arrows') {
      // This is a buff, remove it
    } else if (name === 'Holy Weapon') {
      const name = 'Dismiss Holy Weapon'

      const range = Range.makeWeaponRange(30)

      const saveDCAbility = Utility.shortNameForAbility('constitution')
      const spellSaveDC = character.spellSaveDC()
      const activation = Activation.BonusAction()
      const dice = Dice.Create(4, 8)
      const attributes = {
        range: range,
        type: 'Weapon',
        requiresSavingThrow: true,
        requiresAttackRoll: false,
        saveDcAbility: saveDCAbility,
        saveDcValue: spellSaveDC
      }

      spellAction = new AttackAction(spell.id, name, 0, dice, attributes, activation)
    } else if (name === 'Wrath of Nature') {
      const spellName = 'Wrath of Nature: Trees'
      const saveDCAbility = Utility.shortNameForAbility('dexterity')
      const range = Range.makeSpellAttackRange(60)
      const spellSaveDC = character.spellSaveDC()
      const activation = Activation.Action()
      const dice = Dice.Create(4, 6)
      const attributes = {
        range: range,
        type: 'Spell Attack',
        requiresSavingThrow: true,
        requiresAttackRoll: false,
        saveDcAbility: saveDCAbility,
        saveDcValue: spellSaveDC,
        freeAction: true
      }
      spellAction = new AttackAction(spell.id, spellName, 0, dice, attributes, activation)
      attackActions.push(spellAction)
      // TODO - trees aren't save for half'

      const toHit = character.spellAttackModifier()
      const rocksAttributes = {
        range,
        type: 'Spell Attack',
        requiresAttackRoll: true
      }
      const rocksActivation = Activation.BonusAction()
      const rocksDice = Dice.Create(3, 8)
      const rocksName = 'Wrath of Nature: Rocks'
      spellAction = new AttackAction(spell.id + 1, rocksName, toHit, rocksDice, rocksAttributes, rocksActivation)
    } else {
      spellIdToPrune = undefined
    }

    if (spellAction) {
      attackActions.push(spellAction)
    }

    if (spellIdToPrune) {
      spellIDsToPrune.push(spellIdToPrune)
    }
  }

  static createShadowBlades(parser: ActionParser, spell: Spell, die: Dice, activation: Activation): AttackAction {
    const character = parser.character
    const attackRange = spell.range ? new Range(spell.range) : Range.makeWeaponRange(30)
    const abilityMod = character.finesseAbilityMod()
    const toHit = character.proficiencyBonus() + abilityMod
    die.fixedValue += abilityMod
    const attributes = parser.thrownfinesseWeaponAttrs(attackRange)
    return new AttackAction(spell.id, spell.name, toHit, die, attributes, activation)
  }
}
