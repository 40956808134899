import { Dice } from '../Dice'

import { FeatureParserBase } from './FeatureParserBase'

export class FeatureParserBard {
  static parse(parser: FeatureParserBase) {
    const thisClass = 'Bard'
    if (!parser.validate(thisClass)) return false
    const { name, only, action, feature, effects, character, requiredLevel, isSubclassFeature } = parser
    const classLevel = parser.classLevel(thisClass)

    if (name === 'Slashing Flourish' || name === 'Defensive Flourish' || name === 'Mobile Flourish') {
      const damage = character.bardicInspirationDieSize()
      only.meleeWeapon = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(1, damage)
      return true
    }

    if (name === 'Bardic Inspiration: Agile Strikes') {
      only.unarmed = true
      effects.extraAttackThisAction = true
      feature.usesLimitedResource = true
      feature.notes = 'Make an extra unarmed strike this action'
      return true
    }

    if (name === 'Battle Magic') {
      if (requiredLevel) return feature.skip()
      only.weapon = true
      effects.convertActionToBonusAction = true
      only.oncePerTurn = true
      feature.notes = 'After casting action spell, make a bonus action weapon attack'
      return true
    }

    if (name === 'Psychic Blades') {
      // 2014 College of Whispers Bard, not the same as 2024 Soulknife Rogue skill
      const psychicBlades2024Id: number = 10292922
      const psychicBlades2024ClassId: number = 9414605
      const psychicBladess2024ClassId2: number = 9842722 // They added this later when they added a BA

      const actionId: number = Number(action.id)

      if (isSubclassFeature) return feature.skip()
      if (actionId === psychicBlades2024Id) return feature.skip()
      if (actionId === psychicBlades2024ClassId) return feature.skip()
      if (actionId === psychicBladess2024ClassId2) return feature.skip()

      let dieCount = 2
      if (classLevel >= 15) {
        dieCount = 8
      } else if (classLevel >= 10) {
        dieCount = 5
      } else if (classLevel >= 5) {
        dieCount = 3
      }

      only.weapon = true
      effects.additionalDamageOnHitDice = Dice.Create(dieCount, 6)
      only.oncePerTurn = true
      return true
    }

    const nonBuffActions = [
      'Jack of all Trades',
      'Jack of All Trades',
      'Bardic Inspiration',
      'Countercharm',
      'Magical Inspiration',
      'Spellcasting',
      'Song of Rest',
      'Bard College',
      'Expertise',
      'Ability Score Improvement',
      'Font of Inspiration',
      'Superior Inspiration',
      'Proficiencies',
      'Hit Points',
      'Magical Secrets',
      'Equipment',
      'Bonus Proficiencies',
      'Fighting Style',
      'Blade Flourish',
      'Extra Attack',
      'Master’s Flourish',
      'Bardic Inspiration',
      'Regain Bardic Inspiration',
      'Countercharm',
      'Bardic Damage',
      'Inspiring Movement',
      'Tandem Footwork',
      'Superior Inspiration',
      'Core Bard Traits',
      'Spellcasting',
      'Expertise',
      'Bard Subclass',
      'Ability Score Improvement',
      'Font of Inspiration',
      '8: Ability Score Increase',
      'Magical Secrets',
      '12: Ability Score Improvement',
      '16: Ability Score Improvement',
      'Epic Boon',
      'Words of Creation',
      '9: Expertise',
      'Dazzling Footwork',
      'Leading Evasion',
      'Bardic Inspiration',
      'Regain Bardic Inspiration',
      'Countercharm',
      'Superior Inspiration',
      'Combat Inspiration: Defense',
      'Combat Inspiration: Offense',
      'Core Bard Traits',
      'Spellcasting',
      'Expertise',
      'Bard Subclass',
      'Ability Score Improvement',
      'Font of Inspiration',
      '8: Ability Score Increase',
      'Magical Secrets',
      '12: Ability Score Improvement',
      '16: Ability Score Improvement',
      'Epic Boon',
      'Words of Creation',
      '9: Expertise',
      'Combat Inspiration',
      'Martial Training',
      'Extra Attack',
      'Bardic Inspiration',
      'Regain Bardic Inspiration',
      'Countercharm',
      'Beguiling Magic',
      'Beguiling Magic: Regain Use',
      'Mantle of Inspiration',
      'Mantle of Majesty',
      'Assume Unbreakable Majesty',
      'Superior Inspiration',
      'Core Bard Traits',
      'Spellcasting',
      'Expertise',
      'Bard Subclass',
      'Ability Score Improvement',
      'Font of Inspiration',
      '8: Ability Score Increase',
      'Magical Secrets',
      '12: Ability Score Improvement',
      '16: Ability Score Improvement',
      'Epic Boon',
      'Words of Creation',
      '9: Expertise',
      'Unbreakable Majesty',
      'Cutting Words',
      'Additional Magical Secrets',
      'Peerless Skill',
      'Shapechange',
      'Unsettling Words',
      'Universal Speech',
      'Silver Tongue',
      'Unfailing Inspiration',
      'Infectious Inspiration'
    ]

    if (nonBuffActions.includes(name)) return feature.skip()

    return false
  }
}
