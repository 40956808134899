import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { Feature } from '../../DDB/Feature'
import { DiamondIcon } from '../CustomIcons'

export function FeatureBox({
  feature,
  ps,
  warnConcentration = false
}: {
  feature: Feature
  ps: number
  warnConcentration?: boolean
}) {
  const name = feature.name
  const conc = feature.requiresConcentration
  const fontSize = 'sm'

  return (
    <Flex align="center">
      <Text fontSize={fontSize} pe={conc ? 1 : 0} ps={ps} variant={feature.rarityString}>
        {name}
        {/* : {feature.activation?.activationTypeString()} */}
      </Text>
      {conc && <DiamondIcon color={warnConcentration ? '#FAA' : '#AAA'} letter="C" />}
    </Flex>
  )
}
