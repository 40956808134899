import { Feature } from '../Feature'
import { Character } from '../Character'
import { ActivationType } from '../CharacterJSON/Activation'

export class FeatureParserManeuvers {
  static parse(feature: Feature, character: Character): boolean {
    const name = feature.name
    const { only, effects } = feature

    if (!(name.startsWith('Maneuvers:') || feature.name.startsWith('Maneuver:'))) return false

    const superiorityDice = character.superiorityDice()

    // NOTE: Not doing Reposte, it's a reaction

    if (
      name.startsWith('Maneuver: Goading Attack (') ||
      ['Maneuver: Maneuvering Attack', 'Maneuver: Distracting Strike'].includes(name)
    ) {
      only.attackRoll = true
      effects.isManeuver = true
      effects.additionalDamageOnHitDice = superiorityDice
      feature.usesLimitedResource = true

      return true
    }

    if (name.startsWith('Maneuver: Trip Attack (')) {
      effects.additionalDamageOnHitDice = superiorityDice
      effects.nextAttacksHaveAdvantage = true
      effects.isManeuver = true
      feature.usesLimitedResource = true
      only.weaponOrUnarmed = true
      return true
    }

    if (name === 'Maneuver: Lunging Attack') {
      only.meleeOrUnarmed = true
      effects.isManeuver = true
      effects.additionalDamageOnHitDice = superiorityDice
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'Maneuver: Precision Attack') {
      only.attackRoll = true
      effects.isManeuver = true
      effects.additionalToHitDice = superiorityDice
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'Maneuver: Feinting Attack') {
      only.attackRoll = true
      only.oncePerTurn = true
      effects.isManeuver = true
      effects.applyToNextAttack = true
      effects.advantage = true
      effects.additionalDamageOnHitDice = superiorityDice
      feature.usesLimitedResource = true
      feature.notes = `Advantage and +${effects.additionalDamageOnHitDice.diceString()} dmg on next attack roll`
      return true
    }

    if (name === 'Maneuver: Sweeping Attack') {
      only.weaponOrUnarmed = true
      only.oncePerTurn = true
      effects.isManeuver = true
      effects.additionalDamageOnHitDice = superiorityDice
      effects.applyToNextAttack = true
      effects.extraAttackThisAction = true
      feature.notes = `Extra melee or unarmed attack, +${effects.additionalDamageOnHitDice.diceString()} dmg on next attack`
      return true
    }

    if (name === 'Maneuver: Riposte') {
      only.weaponOrUnarmed = true
      only.oncePerTurn = true
      only.activationType = ActivationType.REACTION
      only.opportunityAttack = true
      effects.isManeuver = true
      effects.additionalDamageOnHitDice = superiorityDice
      return true
    }

    // TODO - DAMN, precision attack is BRUTALLY slow with +1d12

    // 2014
    if (
      [
        'Maneuvers: Trip Attack',
        'Maneuvers: Brace',
        'Maneuvers: Sweeping Attack',
        'Maneuvers: Menacing Attack',
        'Maneuvers: Goading Attack',
        'Maneuvers: Maneuvering Attack',
        'Maneuvers: Pushing Attack',
        'Maneuvers: Lunging Attack',
        'Maneuvers: Disarming Attack'
      ].includes(name)
    ) {
      only.weapon = true
      effects.additionalDamageOnHitDice = superiorityDice
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'Maneuvers: Quick Toss') {
      only.weaponProperties = ['Thrown']
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = superiorityDice
      feature.usesLimitedResource = true
      effects.convertActionToBonusAction = true
      return true
    }

    if (name === 'Maneuvers: Riposte') {
      effects.extraAttackFirstRoundDice = superiorityDice
      if (!effects.extraAttackFirstRoundDice) {
        return false
      }

      only.meleeWeapon = true
      only.oncePerTurn = true // TODO: It's a reaction
      effects.extraAttackThisTurn = true
      feature.usesLimitedResource = true
      feature.notes = `Extra attack to enemy that missed, +${effects.extraAttackFirstRoundDice.diceString()} dmg`
      return true
    }

    if (name === 'Maneuvers: Precision Attack') {
      effects.additionalToHitDice = superiorityDice
      feature.usesLimitedResource = true
      return true
    }

    return false
  }
}
