import { Feature } from '../Feature'
import { Dictionary, Class } from '../../Common/Types'
import { Character } from '../Character'
import { FeatureConstraints } from '../FeatureConstraints'
import { FeatureEffects } from '../FeatureEffects'
import { NON_BUFF_ACTIONS } from '../../Common/Constants'
import { Utility } from '../../Common/Utility'
import { featureSourceNames } from '../FeatureSource'

export class FeatureParserBase {
  feature: Feature
  only: FeatureConstraints
  effects: FeatureEffects
  action: Dictionary
  character: Character
  name: string
  isSubclassFeature: boolean
  requiredLevel?: number
  idsToIgnore: number[]

  constructor(feature: Feature, action: Dictionary, character: Character, idsToIgnore: number[]) {
    this.feature = feature
    this.only = feature.only
    this.effects = feature.effects
    this.action = action
    this.character = character
    this.name = feature.name
    this.requiredLevel = action.requiredLevel
    this.isSubclassFeature = action.entityTypeId === undefined
    this.idsToIgnore = idsToIgnore
  }

  validate(ddbClass: Class): boolean {
    const classLevel = this.classLevel(ddbClass)
    if (classLevel === 0) return false
    if (this.feature.skipped()) return false
    if (this.requiredLevel && classLevel < this.requiredLevel) return this.feature.skip()
    return true
  }

  classLevel(thisClass: Class): number {
    return this.character.classLevel(thisClass)
  }

  is2024Class(thisClass: Class): boolean {
    return this.character.is2024Class(thisClass)
  }

  //   static logNonBuffClassFeatureMap(): string[] {
  //     Object.entries(NonBuffClassFeatureMap).forEach(([className, features]) => {
  //       console.log(`${className}: "${Array.from(features).join('", "')}"`)
  //     })

  //     return Object.values(NonBuffClassFeatureMap)
  //       .flatMap((featureSet) => Array.from(featureSet))
  //       .sort()
  //   }

  logUnknownAction() {
    const { feature, character, action } = this

    // if (NON_BUFF_ACTIONS.includes(this.name) && character.classNames().length === 1) {
    //   const className = character.classNames()[0][0]

    //   if (!NonBuffClassFeatureMap[className]) {
    //     NonBuffClassFeatureMap[className] = new Set()
    //   }
    //   NonBuffClassFeatureMap[className].add(this.name)
    // }

    if (Utility.isDevelopment && !feature.skipped() && !NON_BUFF_ACTIONS.includes(this.name)) {
      console.log(
        `${character.name()}: ${character.classNamesForDisplay()} Unknown possible ${featureSourceNames[feature.featureSource]}: '${this.name}'.`
      )
      console.log(action)
    }
  }
}
