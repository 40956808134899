import { Dictionary } from '../Common/Types'

export type DiceEvaluation = {
  values: DiceMap & {
    private: {
      critValues: DiceMap
    }
  }
  percentiles: DiceMap
  average: number
}

export type DiceMap = { [key: number]: number }

export function total(dice: DiceMap) {
  return Object.values(dice).reduce((sum, v) => sum + v, 0)
}

export function extractHitValues(allValues: DiceMap, critValues: DiceMap) {
  const hitValues: DiceMap = {}
  Object.keys(allValues).forEach((key) => {
    const face = Number(key)
    hitValues[face] = (allValues[face] || 0) - (critValues[face] || 0)
  })
  return hitValues
}

export function average(dice: DiceMap) {
  if (total(dice) === 0) return 0

  return Object.keys(dice).reduce((sum, key) => sum + Number(key) * dice[Number(key)], 0) / total(dice)
}

export function renormalize(hit: DiceMap, pHit: number, crit: DiceMap, pCrit: number) {
  const ret: DiceMap = []

  for (const key of Object.keys(hit)) {
    const face = parseInt(key)
    ret[face] = hit[face] * (pHit - pCrit) + (crit[face] ? crit[face] : 0) * pCrit
  }

  return ret
}

export function normalizeEvaluation(
  evaluation: Dictionary,
  pHit: number,
  pCrit: number,
  damageRider: boolean
): Dictionary {
  if (pHit < 0) console.error('pHit is less than 0')
  if (pHit > 1) console.error('pHit is greater than 1')
  if (pCrit < 0) console.error('pCrit is less than 0')
  if (pCrit > 1) console.error('pCrit is greater than 1')

  const allValues: DiceMap = evaluation ? evaluation.values : {}
  const critValues: DiceMap = evaluation ? evaluation.values.private.critValues || {} : {}
  const hitValues: DiceMap = extractHitValues(allValues, critValues)

  // Damage riders can't crit if they don't have any chance of actually hitting
  if (pHit === 0 && damageRider) pCrit = 0

  const newPercentiles = renormalize(hitValues, pHit, critValues, pCrit) // TODO: I think this is actually wrong, but it should only affect the graphs a tiny amount. Revisit later.
  newPercentiles[0] = 1 - pHit
  const newAverage = average(hitValues) * (pHit - pCrit) + average(critValues) * pCrit

  return { ...evaluation, percentiles: newPercentiles, average: newAverage }
}

export function modifyEvaluationForHalfDamage(
  evaluation: DiceEvaluation,
  originalPercentiles: Dictionary | undefined,
  missDamage: number = 0
): Dictionary {
  const percentilesCopy = { ...evaluation.percentiles }
  const missChance = evaluation.percentiles[0]

  const nonMissOdds = { ...originalPercentiles }
  const originalPercentilesMissChance = nonMissOdds[0]
  nonMissOdds[0] = 0
  let nonMissTotal = 0

  if (missDamage === 0) {
    // TODO - we shouldn't need to do this repeatedly, we can pull it out to where we first cache originalPercentages and cache it
    for (const [damage, odds] of Object.entries(nonMissOdds)) {
      if (Number(damage) === 0) {
        continue
      }

      const fullDamageOdds = Number(odds)
      nonMissOdds[Number(damage)] = fullDamageOdds / (1 - originalPercentilesMissChance)
      nonMissTotal = nonMissTotal + nonMissOdds[Number(damage)]
    }

    percentilesCopy[0] = 0 // didn't miss, so no damage
    const hitChance = 1 - missChance
    let blendTotal = 0

    for (const [damage, odds] of Object.entries(percentilesCopy)) {
      const damageIndex = Number(damage)

      if (damageIndex === 0) {
        continue
      }

      const halfDamageOdds = nonMissOdds[damageIndex] * 0.5
      const fullDamageOdds = Number(odds)
      percentilesCopy[damageIndex] = fullDamageOdds * hitChance + halfDamageOdds * missChance
      blendTotal = blendTotal + percentilesCopy[damageIndex]
    }
  } else {
    // Set the miss chance to 0, then add the miss damage odds to the existing odds
    percentilesCopy[0] = 0
    percentilesCopy[missDamage] = missChance + (percentilesCopy[missDamage] ? percentilesCopy[missDamage] : 0)
  }

  let nonMissAverage = 0
  for (const [damage, odds] of Object.entries(nonMissOdds)) {
    nonMissAverage = nonMissAverage + Number(damage) * Number(odds)
  }

  const average = (1 - missChance) * nonMissAverage + missChance * (missDamage > 0 ? missDamage : 0.5 * nonMissAverage)

  return { ...evaluation, percentiles: percentilesCopy, average }
}

export function critOdds(advantage: number, critRange: number) {
  let odds = 1
  const baseOdds = critRange / 20
  if (advantage === 0) {
    odds = Math.pow(baseOdds, 1)
  } else if (advantage === 1) {
    odds = 1 - Math.pow(1 - baseOdds, 2)
  } else if (advantage === 2) {
    odds = 1 - Math.pow(1 - baseOdds, 3)
  } else if (advantage === -1) {
    odds = Math.pow(baseOdds, 2)
  }
  return odds
}

export function critOddsProduct(odds: number[]) {
  return 1 - odds.reduce((product, chance) => product * (1 - chance), 1)
}
