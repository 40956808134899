import { Dice, DiceCollection } from './Dice'
import { DamageData, NumberMap, DictMap } from '../Common/Interfaces'
import { DiceCalc } from '../Dice/DiceCalc'
import * as DiceMath from '../Dice/DiceMath'
import { DiceEvaluation } from '../Dice/DiceMath'
import { Dictionary, SpellName, SpellSchool, FeatureName, AbilityName } from '../Common/Types'
import { Activation, ActivationType } from './CharacterJSON/Activation'
import { CreatureType } from './CharacterJSON/Creature'
import { DictionaryCache } from '../Common/Cache'
import { AttackAction } from './AttackAction'
import { DamageType } from '../Common/Types'
import { Feature } from './Feature'
import { WeaponProperty } from './WeaponAttributes'

// Hacky to put this here, but it's a temporary solution
const compoundStatsCache = new DictionaryCache()

// TODO put more stuff in here?
export class Scenario {
  hitMultiplier?: number[]
  damage?: DamageData[]
  advantage: number = 0

  chanceToHitAc(ac: number, acs: number[]): number {
    return this.damage![ac - acs[0]].percentiles![0]
  }
}

export class TurnAction {
  attackAction?: AttackAction
  attackNumber: number
  advantage: number = 0 // 1 = advantage, 2 = elven accuracy. TODO make this an enum?
  forgoAdvantage: boolean = false
  disadvantage: boolean = false
  critThreshold: number = 20
  spellSaveDCIncrease: number = 0

  id: number
  damageRider: boolean = false
  damageRiderFeatureId: number = 0

  activation?: Activation = undefined // Hack, lets engine override the activation type
  minimumD20Roll: number = 0
  bonusToHitDice: Dice[] = []
  bonusDamageDiceCollection: DiceCollection = new DiceCollection()
  replacementDamageDice?: Dice
  singleEffectBonusDamageDiceCollection: DiceCollection = new DiceCollection()
  rerollDamageDiceThreshold: number = 0
  rerollAllDamageDiceOnHit: boolean = false
  rerollToHit: boolean = false
  maxDamage: boolean = false
  autoCrit: boolean = false
  autoHit: boolean = false
  minimumDieRoll: number = 0
  missForHalfDamage: boolean = false // Potent Cantrip
  saveForHalfDamage: boolean = false // Potent Cantrip
  missDamage: number = 0 // Mastery: Graze
  specificweaponMasteryType: string | undefined = undefined
  damageMultiplier: number = 1

  critDiceDealMaxDamage: boolean = false
  rerollDamageDice: number = 0
  subduePositiveDamageModifier: boolean = false

  damageVulnerability: boolean = false
  additionalCritDiceCount: number = 0
  additionalDamageOnCritDice: Dice | undefined = undefined
  additionalDamageDice: number = 0
  damageData?: DamageData[]
  modifierOverride: number | undefined = undefined // only used to prevent doing this multiple times

  userScenario: Scenario = new Scenario()
  flatScenario: Scenario = new Scenario()
  advantageScenario: Scenario = new Scenario()
  disadvantageScenario: Scenario = new Scenario()

  extendedDamageDataACs?: number[]

  constructor(attackNumber: number, action?: AttackAction) {
    if (action) {
      this.attackAction = action
      this.id = action.attributes.id + 100 * attackNumber
    } else {
      this.id = 1000000000 + attackNumber
    }
    this.attackNumber = attackNumber
  }

  advantageType(): number {
    if (this.attackAction === undefined) {
      return 0
    }

    if (this.advantage) {
      return this.advantage
    }

    if (this.disadvantage) {
      return -1
    }

    // TODO later we can invalidate advantage if we have disadvantage, but make sure we do that in the damage calculations as well
    return 0
  }

  private attackAttributes(): Dictionary {
    return this.attackAction?.attributes || {}
  }

  hasFreeAction(): boolean {
    return this.attackAttributes().freeAction
  }

  isCustomized(): boolean {
    return this.attackAttributes().isCustomized
  }

  isFeatureNameEnabled(features: Feature[], name: FeatureName): boolean {
    return features.some((feature) => feature.name === name)
  }

  isDamagelessWeapon(): boolean {
    return this.isWeapon() && this.attackAttributes().dealsDamage !== undefined && !this.attackAttributes().dealsDamage
  }

  isBow(): boolean {
    return this.isWeapon() && this.attackAttributes().isBow
  }

  isCrossbow(): boolean {
    return this.isWeapon() && this.attackAttributes().isCrossbow
  }

  isHorns(): boolean {
    return this.attackAttributes().isHorns
  }

  hasSomeWeaponProperties(properties: WeaponProperty[]): boolean {
    return properties.some((property) => this.hasWeaponProperty(property))
  }

  hasWeaponProperty(property: WeaponProperty): boolean {
    const weaponProperties = this.attackAttributes().propertyNames as WeaponProperty[]
    return this.isWeapon() && weaponProperties && weaponProperties.includes(property)
  }

  isSpellSchool(school: SpellSchool): boolean {
    return this.attackAttributes().school === school
  }

  isSpell(): boolean {
    const type = this.attackAttributes().type
    return type === 'Spell' || type === 'Spell Attack'
  }

  isSpellNamed(name: SpellName): boolean {
    return this.attackAttributes().name === name
  }

  isSingleTargetSpell(): boolean {
    if (this.name() === 'Acid Splash') {
      // It's not AOE, but it's not single target either. TODO: Figure out how to detect this from the json.
      return false
    }
    const aoeType = this.attackAttributes().range.aoeType
    return aoeType === null || aoeType === undefined
  }

  isDamageTypes(types: DamageType[]): boolean {
    return types.some((type) => this.isDamageType(type))
  }

  isDamageType(type: string): boolean {
    return this.attackAttributes().damageTypes?.includes(type)
  }

  isPactWeapon(): boolean {
    return this.attackAttributes().isPactWeapon
  }

  isHexWeapon(): boolean {
    return this.attackAttributes().isHexWeapon
  }

  isBonusAction(): boolean {
    return this.attackAttributes().activationType === ActivationType.BONUS_ACTION
  }

  isReaction(): boolean {
    return this.attackAttributes().activationType === ActivationType.REACTION
  }

  isActivationType(activationType: ActivationType): boolean {
    return this.attackAttributes().activationType === activationType
  }

  isOpportunityAttack(): boolean {
    return this.attackAttributes().opportunityAttack
  }

  weaponType() {
    return this.attackAttributes().weaponType
  }

  isWeaponType(weaponType: string | undefined): boolean {
    return this.attackAttributes().weaponType === weaponType
  }

  isOffHand(): boolean {
    return this.isWeapon() && this.attackAttributes().isOffHand
  }

  isMeleeOrUnarmed(): boolean {
    return this.isUnarmed() || this.isMeleeWeapon()
  }

  isMundaneWeapon(): boolean {
    return this.isWeapon() && this.attackAttributes().magicBonus === 0
  }

  isMagicWeapon(): boolean {
    return this.isWeapon() && (this.attributes().isMagical || this.attributes().isAttuned)
  }

  isMeleeWeapon(): boolean {
    return this.isWildShapeAttack() || (this.isWeapon() && !this.hasWeaponProperty('Range') && !this.isUnarmed())
  }

  isNonTwoHandedWeapon(): boolean {
    return this.isWeapon() && !this.hasWeaponProperty('Two-Handed')
  }

  isAttackRoll(): boolean {
    return this.attackAttributes().requiresAttackRoll
  }

  isSpellAttack(): boolean {
    return this.isSpell() && this.attackAttributes().requiresAttackRoll
  }

  isSpellWithSave(): boolean {
    return this.isSpell() && this.attackAttributes().requiresSavingThrow
  }

  spellLevel(): number {
    return this.isSpell() ? this.attackAttributes().level : 0
  }

  isCantrip(): boolean {
    return this.isSpell() && this.attackAttributes().level === 0
  }

  isUnarmedBonusAction(): boolean {
    return this.isUnarmed() && this.isBonusAction()
  }

  isUnarmed(): boolean {
    return this.attackAttributes().subType === 'unarmed'
  }

  isAbilityNamed(name: AbilityName): boolean {
    return this.attackAttributes().abilityName === name
  }

  isMonkWeapon(): boolean {
    return this.isWeapon() && this.attackAttributes().isMonkWeapon
  }

  isWeapon(): boolean {
    const attributes = this.attributes()

    if (attributes.type && attributes.type === 'Companion') {
      return false
    }

    if (attributes.type && attributes.type === 'Weapon') {
      return !this.isUnarmed()
    }

    if (attributes.range && attributes.range.origin === 'Weapon') {
      return true
    }

    return false
  }

  isWildShapeAttack(): boolean {
    if (this.attackAction === undefined) {
      return false
    }
    const attributes = this.attributes()
    return attributes && attributes.creatureType === CreatureType.WildShape
  }

  isCompanion(): boolean {
    const attributes = this.attributes()
    return attributes.type && attributes.type === 'Companion' && attributes.creatureType !== CreatureType.WildShape
  }

  name(): string {
    return this.attackAction?.name ?? ''
  }

  attackStatIndex(): number {
    return this.attackAttributes().attackStatIndex ?? 0
  }

  attackRollToHitDiceCollection(): DiceCollection {
    if (!this.attackAction) {
      return new DiceCollection()
    }
    const baseDie: Dice = Dice.flatAmountDie(this.attackAction.attackMod)
    if (this.attackAction.attributes.requiresAttackRoll) {
      const diceArray: Dice[] = [baseDie, ...this.bonusToHitDice]
      return new DiceCollection().addDiceList(diceArray)
    }

    return new DiceCollection().addDice(baseDie)
  }

  hasCompoundToHitDice(): boolean {
    if (this.isDamagelessWeapon()) return false
    const hasDiceCountGreaterThanZero = this.bonusToHitDice.some((dice) => dice.diceCount > 0)
    return this.attackAction && this.attackAction.attributes.requiresAttackRoll && hasDiceCountGreaterThanZero
  }

  baseToHitString(): string {
    return this.attackAction?.attackModString(this.spellSaveDCIncrease) ?? ''
  }

  consolidatedToHitString(): string {
    if (!this.attackAction) {
      return ''
    }

    if (this.attackAction.attributes.requiresAttackRoll) {
      const diceCollection = this.attackRollToHitDiceCollection()
      return diceCollection.displayString(0, true)
    }

    return this.attackAction.attackModString(this.spellSaveDCIncrease)
  }

  baseDiceCollectionForLevel(level: number): DiceCollection | null {
    if (this.isDamagelessWeapon()) return null
    if (level <= 0 || !this.attackAction) {
      return null
    }

    const levelMap = this.attributes().diceCollectionsForLevels
    if (!levelMap || levelMap.length === 0) {
      return null
    }

    return levelMap[level - 1]
  }

  baseDiceCollection(level: number): DiceCollection {
    let leveledDice = this.baseDiceCollectionForLevel(level)

    if (leveledDice) {
      if (this.additionalDamageDice > 0) {
        const diceKeys = Object.keys(leveledDice.dice)
        if (diceKeys.length === 1) {
          const key = parseInt(diceKeys[0])
          const value = leveledDice.dice[key] + this.additionalDamageDice
          leveledDice = new DiceCollection().addDice(Dice.Create(value, key, leveledDice.modifier))
        } else {
          console.warn('Multiple dice in baseDiceCollectionForLevel?', leveledDice)
        }
      }

      return leveledDice
    }

    if (this.isDamagelessWeapon()) return new DiceCollection()
    let effectCount = this.effectCount()
    if (level > 1) {
      const effectCountsForLevels = this.attributes().effectCountsForLevels
      if (effectCountsForLevels && effectCountsForLevels.length > 0) {
        effectCount = effectCountsForLevels[level - 1]
      }
    }

    const dice = this.replacementDamageDice ? this.replacementDamageDice.copy() : this.attackAction!.dice.copy() // attackAction should always exist at this point (famous last words), even if there is no level (weapon, cantrip, etc)

    if (effectCount > 1) {
      dice.diceCount *= effectCount
      dice.fixedValue *= effectCount
    }

    if (this.additionalDamageDice > 0) {
      dice.diceCount += this.additionalDamageDice
    }

    if (this.subduePositiveDamageModifier && dice.fixedValue > 0) {
      dice.fixedValue = this.attackAttributes().magicBonus
    }

    if (this.attributes().bonusDamageDice) {
      return this.attributes().bonusDamageDice.copy().addDice(dice)
    }

    return new DiceCollection().addDice(dice)
  }

  effectCount(): number {
    return this.attributes().effectCount || 1
  }

  allDamageDiceCollection(level: number): DiceCollection {
    if (!this.attackAction || this.isDamagelessWeapon()) {
      return new DiceCollection()
    }

    const baseDiceCollection = this.baseDiceCollection(level)
    let bonusDiceCollection = this.bonusDamageDiceCollection.copy()
    const effectCount = this.effectCount()
    if (effectCount > 1) {
      bonusDiceCollection = bonusDiceCollection.multiplyDice(effectCount, effectCount)
    }

    const singleEffectBonusDiceCollection = this.singleEffectBonusDamageDiceCollection.copy() // copy redundant?
    const allDamageDice = baseDiceCollection
      .copy()
      .addDiceCollection(bonusDiceCollection)
      .addDiceCollection(singleEffectBonusDiceCollection)

    if (this.damageMultiplier > 1) {
      allDamageDice.multiplyDice(this.damageMultiplier, this.damageMultiplier)
    }

    return this.damageVulnerability ? allDamageDice.multiplyDice(2, 2) : allDamageDice
  }

  consolidatedDamageStringForLevel(level: number, forDisplay: boolean = false): string {
    if (this.isDamagelessWeapon()) return '0'
    if (this.attackAction === undefined) return ''

    const rerollThreshold = this.rerollDamageDiceThreshold
    const rerollAllDice = this.rerollAllDamageDiceOnHit
    const minDieRoll = this.minimumDieRoll
    const rerollDamageDice = this.rerollDamageDice

    if (!forDisplay && (rerollThreshold > 0 || rerollAllDice || minDieRoll > 0 || rerollDamageDice > 0)) {
      const baseDiceCollection = this.baseDiceCollection(level).copy()
      const bonusDiceCollection = this.bonusDamageDiceCollection.copy()
      // TODO - not using effectCount here as a multiplier for bonus damage dice, should we? See other use of multiplyDiceCollectionAndModifier.

      if (this.damageMultiplier > 1) {
        baseDiceCollection.multiplyDice(this.damageMultiplier, this.damageMultiplier)
        bonusDiceCollection.multiplyDice(this.damageMultiplier, this.damageMultiplier)
      }
      // Manually consolidate the modifier, as we've split apart the base dice and bonus dice
      bonusDiceCollection.modifier += baseDiceCollection.modifier
      baseDiceCollection.modifier = 0

      const baseDiceString = baseDiceCollection.displayString(rerollThreshold, false, minDieRoll, rerollDamageDice)
      const bonusDiceString = bonusDiceCollection.displayString() // TODO minimumDieRoll for bonus dice… for things like green-flame blade?
      const appendBonusString = bonusDiceString.length > 0 ? ` + ${bonusDiceString}` : ''
      let total = `${baseDiceString}${appendBonusString}`

      // TODO… later we need reroll some dice (GWF) and reroll all dice (Savage Attacker) for bonus dice too, gotta do this in the crit logic as well

      if (rerollAllDice) {
        total = `2kh1(${baseDiceString})${appendBonusString}`
      }

      return total.replace(/\+\s*\+/g, '+').replace(/\s+/g, ' ')
    }

    let diceCollection = this.allDamageDiceCollection(level)

    if (this.maxDamage) {
      diceCollection = diceCollection.maxDice()
    }

    return diceCollection.displayString()
  }

  critDiceCollectionForLevel(level: number): DiceCollection {
    if (this.isDamagelessWeapon()) return new DiceCollection()

    // TODO: This doesn't double GWF or Flames of Phlegethos rerolls, but it should
    const diceCollection = this.allDamageDiceCollection(level)
    if (!this.isAttackRoll()) {
      // ASSUMPTION: Only attack rolls double crit dice
      return diceCollection
    }

    let critDiceCollection = diceCollection.copy().multiplyDice(2)
    if (this.critDiceDealMaxDamage) {
      const collection = diceCollection.copy()
      collection.modifier = 0
      const maxDamageDiceCollection = collection.maxDice()
      critDiceCollection = diceCollection.addDiceCollection(maxDamageDiceCollection)
    }

    if (this.attributes().critDamageDice) {
      critDiceCollection.addDice(this.attributes().critDamageDice)
    }

    if (this.additionalCritDiceCount > 0) {
      const weaponDieSize = this.attackAction!.dice.diceValue
      const additionalCritDice = Dice.Create(this.additionalCritDiceCount, weaponDieSize)
      critDiceCollection.addDice(additionalCritDice)
    }

    if (this.additionalDamageOnCritDice) {
      critDiceCollection.addDice(this.additionalDamageOnCritDice)
    }

    return critDiceCollection
  }

  diceString(): string {
    if (!this.attackAction) {
      return ''
    }

    const diceCollection = this.bonusDamageDiceCollection.copy()
    return diceCollection.addDice(this.attackAction.dice).displayString()
  }

  attributes() {
    return this.attackAction?.attributes ?? {}
  }

  diceModStringForAC(ac: number) {
    return this.diceModString(ac, this.advantage, this.disadvantage)
  }

  asSpecifiedDamageDataForACs(acs: number[]): DamageData[] {
    const scenario = this.userScenario.damage
    if (!scenario || !this.extendedDamageDataACs) return []

    const firstAC = this.extendedDamageDataACs[0]
    return acs.map((ac) => scenario![ac - firstAC])
  }

  advantageWithOverride(
    advantageOverride: number | undefined,
    disadvantageOverride: boolean | undefined
  ): [number, boolean] {
    let advantage: number = advantageOverride !== undefined ? advantageOverride : this.advantage
    const disadvantage = disadvantageOverride !== undefined ? disadvantageOverride : this.disadvantage

    if (advantage === 2 && this.isCompanion()) {
      advantage = 1
    }

    return [advantage, disadvantage]
  }

  critOdds(scenario: Scenario) {
    // Using the -1->2 adv type
    return DiceMath.critOdds(scenario.advantage, 21 - this.critThreshold)
  }

  calculateAverageScenarioDamage(
    scenario: Scenario,
    acList: number[],
    advantageOverride?: number,
    disadvantageOverride?: boolean,
    critOdds?: number
  ) {
    if (!this.attackAction) {
      return []
    }

    const damageMultipliers = scenario?.hitMultiplier

    const damageArray: DamageData[] = []
    const [advantage, disadvantage] = this.advantageWithOverride(advantageOverride, disadvantageOverride)

    let lastValue: Dictionary | undefined = undefined
    const lastToHitEvaluation: DictMap = {} // only for compound hit dice
    for (const armorClass of acList) {
      const diceString = this.diceModString(armorClass, disadvantage ? 0 : advantage, disadvantage)
      // TODO don't store and cache the compound +1d4 stuff in the normal cache
      let evaluation = undefined

      if (!this.hasCompoundToHitDice()) {
        evaluation = DiceCalc.evaluateStatistics(diceString)
      } else if (compoundStatsCache.has(diceString)) {
        evaluation = compoundStatsCache.get(diceString)
      } else {
        const diceCollection = this.attackRollToHitDiceCollection()
        const freqMap = diceCollection.diceSumFrequency()

        let averageSum: number = 0
        const percentilesSum: NumberMap = new NumberMap()
        let freqTotal = 0

        for (const [attackMod, frequency] of Object.entries(freqMap)) {
          const compoundStatDiceString = this.diceModString(
            armorClass,
            disadvantage ? 0 : advantage,
            disadvantage,
            attackMod
          )

          let evaluation = DiceCalc.evaluateStatistics(compoundStatDiceString)
          if (evaluation?.percentiles[0] === 1) {
            evaluation = lastToHitEvaluation[armorClass - Number(attackMod) - 1]
            lastToHitEvaluation[armorClass - Number(attackMod)] = evaluation
          } else {
            lastToHitEvaluation[armorClass - Number(attackMod)] = evaluation!
          }

          const percentiles = evaluation?.percentiles
          const average = evaluation?.average
          averageSum += average * frequency
          freqTotal += frequency
          for (const [percentile, value] of Object.entries(percentiles)) {
            const p = Number(percentile)
            const v = Number(value)
            if (percentilesSum[p] !== undefined) {
              percentilesSum[p] += v * frequency
            } else {
              percentilesSum[p] = v * frequency
            }
          }
        }

        const newAverage = averageSum / freqTotal
        for (const [percentile] of Object.entries(percentilesSum)) {
          percentilesSum[Number(percentile)] /= freqTotal
        }

        if (Number.isNaN(newAverage)) {
          console.error(`Failed to evaluate dice string [${diceString}] - NaN`)
        }

        evaluation = {
          average: newAverage,
          total: 0,
          expression: diceString,
          percentiles: percentilesSum
        }

        compoundStatsCache.set(diceString, evaluation)
      }

      // Weapon Masteries & Potent Cantrips
      if (evaluation && this.isAttackRoll() && (this.missDamage > 0 || this.missForHalfDamage)) {
        const baseDamageString = this.diceModString(5, disadvantage ? 0 : advantage, disadvantage, '+30')
        const baseEval = DiceCalc.evaluateStatistics(baseDamageString)
        evaluation = DiceMath.modifyEvaluationForHalfDamage(
          evaluation as DiceEvaluation,
          baseEval!.percentiles,
          this.missDamage
        )
      }

      // If discressional damage riders are on, scale the damage appropriately
      // NOTE: THIS IS NOT WORKING YET!
      if (evaluation && damageMultipliers && damageMultipliers.length === acList.length) {
        const pCrit = critOdds || 0.05
        const pHit = damageMultipliers[armorClass - acList[0]]

        // Only for appropriate attack roll attacks should we do this
        if (!Number.isNaN(pHit)) {
          evaluation = DiceMath.normalizeEvaluation(evaluation, pHit, pCrit, this.damageRider)
        }
      }

      if (evaluation === undefined) {
        console.error(`Failed to evaluate dice string [${diceString}]`)
      } else if (lastValue && evaluation.average === 0) {
        evaluation.values = {}
        damageArray.push(lastValue as DamageData)
        // Warning: This is a hack because dice.clockworkmod.com doesn't consider a nat 20 a hit if the AC is impossible to hit, but this seems to do the trick
      } else {
        damageArray.push(evaluation as DamageData)
        lastValue = evaluation
      }
    }

    scenario.damage = damageArray
  }

  diceModString(
    ac: number,
    advantage: number = 0,
    disadvantage: boolean = false,
    toHitOverride: string | null = null
  ): string {
    if (!this.attackAction) {
      return ''
    }

    if (advantage > 0 && disadvantage) {
      advantage = 0
      disadvantage = false
      console.warn('Cannot have advantage and disadvantage at the same time')
    }
    const baseDamageDiceString = this.consolidatedDamageStringForLevel(this.attackAction.turnLevel)
    const attrs = this.attributes()

    let d20String = this.rerollToHit ? 'hd20' : 'd20'
    if (this.minimumD20Roll > 0) {
      d20String = `${this.minimumD20Roll}>${d20String}`
    }

    if (attrs.requiresAttackRoll) {
      const toHitString =
        toHitOverride !== null
          ? new DiceCollection().addDice(Dice.flatAmountDie(parseInt(toHitOverride))).displayString(0, true)
          : this.consolidatedToHitString()

      let string = `(${d20String}`

      if (advantage > 0) {
        for (let i = 0; i < advantage; i++) {
          string += ` > ${d20String}`
        }
      } else if (disadvantage) {
        string += ` < ${d20String}`
      }

      const level = this.attackAction.turnLevel
      const critDice = this.critDiceCollectionForLevel(level)

      const rerollThreshold = this.rerollDamageDiceThreshold
      const minDieRoll = this.minimumDieRoll
      const rerollAllDice = this.rerollAllDamageDiceOnHit

      let critString = critDice.displayString(rerollThreshold, false, minDieRoll)
      if (rerollAllDice) {
        critString = `2kh1(${critString})`
      }

      if (this.autoCrit) {
        if (this.autoHit) {
          string += `) * (${critString})`
        } else {
          string += ' ' + toHitString + ' AC ' + ac + ') * (' + critString + ')'
        }
      } else {
        if (this.autoHit) {
          string += ') * (' + baseDamageDiceString + ')'
        } else {
          string += ' ' + toHitString + ' AC ' + ac + ') * (' + baseDamageDiceString + ')'
        }

        if (this.critThreshold < 20) {
          const extra = 21 - this.critThreshold
          string += ' xcrit' + extra + ' (' + critString + ')'
        } else {
          string += ' crit (' + critString + ')'
        }
      }

      return string
    }

    if (attrs.requiresSavingThrow) {
      // TODO - need to handle stuff like Bane
      const saveBonus = ac - 10 // hack for now
      const saveBonusString = saveBonus >= 0 ? `+ ${saveBonus}` : saveBonus
      const saveDC = attrs.saveDcValue + this.spellSaveDCIncrease
      const isCantrip = attrs.isCantrip
      const saveString = isCantrip && !this.saveForHalfDamage ? '' : 'save half' // TODO - Assumption for now. Assuming all spells that require saving throw have a "save half"

      return `(${d20String} ${disadvantage ? `< ${d20String} ` : ''}${advantage ? `> ${d20String} ` : ''} ${saveBonusString} DC ${saveDC}) * (${baseDamageDiceString}) ${saveString}`
    }

    return baseDamageDiceString
  }
}
