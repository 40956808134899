import React from 'react'
import { HStack, Box, VStack, Flex, Link, Text, Badge } from '@chakra-ui/react'
import { Dictionary } from '../../Common/Types'
import { DiamondIcon } from '../CustomIcons'
import { IconTooltip } from '../../Common/UIComponents'
import { Utility } from '../../Common/Utility'

export function ActionBox({
  name,
  attributes,
  level,
  showLevel,
  url,
  activeWeaponMasteries,
  ps,
  tooltip,
  fontStyle
}: {
  name: string
  attributes: Dictionary
  level: number
  showLevel: boolean
  url?: string
  activeWeaponMasteries?: string[] | undefined
  ps?: number
  tooltip?: string
  fontStyle?: string
}) {
  const { requiresConcentration, ritual, effectCountLabel, effectCountsForLevels } = attributes
  let effectCount = attributes.effectCount
  if (!attributes.isCantrip && effectCountsForLevels && effectCountsForLevels.length > 0) {
    effectCount = effectCountsForLevels[Math.max(0, level - 1)]
  }

  const { weaponMastery, weaponType } = attributes
  const masteryStar = activeWeaponMasteries && activeWeaponMasteries.includes(weaponType) ? '★' : '☆'

  const match = name.match(/^(.*?)\s*\((.*?)\)$/)
  const baseName = match ? match[1].trim() : name
  const parenthetical = match ? match[2] : undefined

  const displayAttributes = attributes.displayAttributes ? [...attributes.displayAttributes] : []

  if (displayAttributes && effectCount > 1 && effectCountLabel) {
    displayAttributes.push(`${effectCountLabel}: ${effectCount}`)
  }

  const paddingStart = ps ? ps : 0
  return (
    <HStack>
      <Box display="flex" alignItems="center" ps={paddingStart}>
        <VStack align="left">
          <Flex wrap="wrap" gap="1" alignItems="center">
            {url ? (
              <Flex>
                <Link href={url} isExternal textDecoration="underline">
                  {baseName}
                </Link>
                {parenthetical && <Text>&nbsp;({parenthetical})</Text>}
              </Flex>
            ) : (
              <Text textAlign="left" variant={attributes.rarityString} fontStyle={fontStyle}>
                {name}
                <IconTooltip>{tooltip}</IconTooltip>
              </Text>
            )}

            {requiresConcentration && <DiamondIcon letter="C" />}

            {weaponMastery && <Text>{masteryStar}</Text>}

            {ritual && <DiamondIcon letter="R" />}
            {showLevel && (
              <Badge
                bgColor="brand"
                color="white"
                borderRadius="full"
                px={2}
                py={0}
                maxH={'20px'}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                L{level}
              </Badge>
            )}
          </Flex>

          {displayAttributes && displayAttributes.length > 0 && (
            <Text fontSize="xs" marginTop="-4px">
              {Utility.toTitleCase(displayAttributes.join(', '))}
            </Text>
          )}
        </VStack>
      </Box>
    </HStack>
  )
}
