import { ActionParser } from './ActionParser'
import { AttackAction } from '../AttackAction'

import { Utility } from '../../Common/Utility'
import { Dice } from '../Dice'
import { RacialTrait } from '../CharacterJSON/Race'
import { Dictionary } from '../../Common/Types'
import { NON_BUFF_RACIAL_TRAITS } from '../FeatureParsers/FeatureParserRacialTrait'

export class ActionParserRacialTrait {
  static parse(actionParser: ActionParser, trait: RacialTrait, attackActions: AttackAction[]) {
    const character = actionParser.character

    const traitDefinition = trait.definition
    const traitName = traitDefinition.name

    if (traitName === 'Claws') {
      let clawDice = Dice.Create(1, 4) // Leonin
      if (character.race() === 'Tabaxi' || character.race() === 'Tortle') {
        clawDice = Dice.Create(1, 6)
      }
      const attack = this.createBasicUnarmedStrikeAttackAction(actionParser, traitDefinition, clawDice)
      attackActions.push(attack)
    } else if (['Bite', 'Horns', 'Talons', 'Cat’s Claws', 'Ram'].includes(traitName)) {
      const attack = this.createBasicUnarmedStrikeAttackAction(actionParser, traitDefinition, Dice.Create(1, 6))
      attack.attributes.isHorns = traitName === 'Horns'
      attackActions.push(attack)
    } else if (!NON_BUFF_RACIAL_TRAITS.includes(traitName)) {
      Utility.isDevelopment && console.log(`Unknown Racial Trait: ${traitName}`)
    }
  }

  static createBasicUnarmedStrikeAttackAction(parser: ActionParser, trait: Dictionary, dice: Dice): AttackAction {
    return parser.createUnarmedStrikeAttackAction(parser.character, trait.name, parser.action, trait.id, dice)
  }
}
