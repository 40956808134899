import { Dice } from './Dice'
import { Activation, ActivationType } from './CharacterJSON/Activation'
import { Dictionary } from '../Common/Types'
import { Weapon } from './Weapon'
import { Character } from './Character'
import { Utility } from '../Common/Utility'
import { Feature } from './Feature'

export class AttackAction {
  id: number
  name: string
  attackMod: number
  activation: Activation
  attributes: Dictionary
  dice: Dice
  turnLevel: number = 0
  description?: string // Currently just for creature attacks
  simulated: boolean = false

  constructor(
    id: number,
    name: string,
    attackMod: number,
    dice: Dice,
    attributes: Dictionary = {},
    activation: Activation,
    description?: string
  ) {
    this.id = id
    this.name = name
    this.attackMod = attackMod
    this.dice = dice.copy()
    this.activation = activation
    this.description = description

    this.attributes = attributes
    this.attributes.activationType = activation.activationType // Not sure why this isn't plumbed in earlier…

    if (!this.attributes.id) {
      this.attributes.id = this.id
    }

    // Manually created attacks (summons, etc) - default to true without having to keep passing them in
    if (this.attributes.requiresAttackRoll === undefined) {
      this.attributes.requiresAttackRoll = true
    }
  }

  diceString(): string {
    return this.dice.diceString()
  }

  diceStringForLevel(level: number): string {
    if (
      level > 0 &&
      this.attributes.diceCollectionsForLevels &&
      this.attributes.diceCollectionsForLevels.length > 0 &&
      this.attributes.diceCollectionsForLevels.length >= level - 1
    ) {
      return this.attributes.diceCollectionsForLevels[level - 1].displayString()
    }

    if (this.attributes.bonusDamageDice) {
      const diceCollection = this.attributes.bonusDamageDice.copy()
      return diceCollection.addDice(this.dice).displayString()
    }

    return this.dice.diceString()
  }

  copy(): AttackAction {
    return new AttackAction(
      this.id,
      this.name,
      this.attackMod,
      this.dice.copy(),
      { ...this.attributes },
      this.activation
    )
  }

  attackModString(increase: number = 0): string {
    if (this.attributes.requiresAttackRoll) {
      if (this.attackMod > 0) {
        return '+' + this.attackMod.toString()
      }
      if (this.attackMod < 0) {
        return this.attackMod.toString()
      }

      return '+0'
    }

    if (this.attributes.requiresSavingThrow) {
      return this.saveDCString(increase)
    }

    return ''
  }

  saveDCString(increase: number = 0): string {
    const ability = this.attributes.saveDcAbility
    const value = this.attributes.saveDcValue + increase

    if (ability && value) {
      return ability + ' ' + value
    }
    return ''
  }

  attackString(): string {
    const diceString = this.diceString()
    if (this.attackMod > 0) return '+' + this.attackMod.toString() + ' ' + diceString
    if (this.attackMod < 0) return this.attackMod.toString() + ' ' + diceString
    return diceString
  }

  static CreateFromWeapon(weapon: Weapon, character: Character, offhand: boolean = false) {
    const { name, customizedName, magicBonus, damageMod, attackStatIndex, toHitBonus, toHitOverride, baseDice } = weapon

    const displayName = customizedName ? customizedName : name

    const pb = character.proficiencyBonus()
    const abilityScore = character.abilityScoreForIndex(attackStatIndex)
    const statModifier = Utility.modifierForScore(abilityScore)

    const attributes = weapon.weaponAttributes()
    const dice = baseDice.copy()

    let attackDamageMod = damageMod + magicBonus + statModifier
    if (!attributes.dealsDamage) attackDamageMod = 0

    let attackMod = magicBonus + statModifier + pb + toHitBonus
    if (toHitOverride !== 0) attackMod = toHitOverride

    const activation = offhand ? Activation.BonusAction() : Activation.Action()
    if (!offhand || attackDamageMod < 0) {
      dice.fixedValue += attackDamageMod
    } else {
      dice.fixedValue += magicBonus
    }

    return new AttackAction(weapon.id, displayName, attackMod, dice, attributes, activation)
  }

  static CreateFromDamageRider(feature: Feature) {
    const { id, name } = feature
    const dice = feature.effects.additionalDamageOnHitDice!.copy()
    const constraint = feature.only.stringForConstraints() || ''
    const attributes = { displayAttributes: [constraint] }
    return new AttackAction(id, name, 0, dice, attributes, Activation.Action())
  }

  isAction() {
    return this.activation.activationType === ActivationType.ACTION
  }

  isBonusAction() {
    return this.activation.activationType === ActivationType.BONUS_ACTION
  }

  isReaction() {
    return this.activation.activationType === ActivationType.REACTION
  }
}
