import { DamageType } from '../../Common/Types'
import { Dice } from '../Dice'

import { FeatureParserBase } from './FeatureParserBase'

export class FeatureParserSorcerer {
  static parse(parser: FeatureParserBase) {
    const thisClass = 'Sorcerer'
    if (!parser.validate(thisClass)) return false
    const { name, only, action, feature, effects, character, requiredLevel, isSubclassFeature } = parser
    const classLevel = parser.classLevel(thisClass)

    if (name === 'Innate Sorcery' && requiredLevel && action.id === 10292362) {
      only.spell = true
      effects.spellSaveDCIncrease = 1
      feature.name = 'Innate Sorcery (Spell Save DC)'
      feature.notes = '+1 to Sorcerer Spell Save DC'
      return true
    }

    if (name === 'Innate Sorcery' && !requiredLevel) {
      only.spellAttack = true
      effects.advantage = true
      feature.name = 'Innate Sorcery (Spell Attack)'
      feature.notes = 'Advantage on Sorcerer spell attack rolls'
      return true
    }

    // At this point it's the ones to skip
    if (name === 'Innate Sorcery') return feature.skip()

    if (name === 'Heart of the Storm') {
      if (isSubclassFeature) return feature.skip()
      const sorcererLevel = classLevel
      const damage = Math.ceil(sorcererLevel / 2)

      only.damageTypes = ['lightning', 'thunder']
      only.spell = true
      only.oncePerTurn = true
      feature.usesLimitedResource = true
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(damage)
      feature.notes = `+${damage} dmg to lightning or thunder spells`
      // TODO: Notes is inaccurate, later this will scale with # of enemies
      return true
    }

    if (name === 'Trance of Order') {
      if (isSubclassFeature) return feature.skip()
      only.attackRoll = true
      effects.minimumD20Roll = 10
      feature.usesLimitedResource = true
      feature.notes = 'Treat rolls below 9 on d20 as a 10'
      return true
    }

    if (name === 'Tides of Chaos') {
      if (isSubclassFeature) return feature.skip()
      only.attackRoll = true
      only.oncePerTurn = true
      effects.advantage = true
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'Spell Bombardment') {
      if (isSubclassFeature) return feature.skip()
      only.spell = true
      only.oncePerTurn = true
      effects.additionalDamageDiceOnHit = 1
      feature.usesLimitedResource = true
      feature.notes = 'Add 1 damage die to next spell' // Close enough
      return true
    }

    if (name === 'Favored by the Gods') {
      if (isSubclassFeature) return feature.skip()
      only.attackRoll = true
      only.oncePerTurn = true
      effects.additionalToHitDice = Dice.Create(2, 4)
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'Elemental Affinity') {
      const elementalType = feature.options[0]?.definition?.name?.split(' ')[0]
      if (!elementalType) return feature.skip()

      const damageType = elementalType.toLowerCase()

      feature.name = `${feature.name} (${elementalType})`
      feature.only.oncePerTurn = true
      feature.only.spell = true
      feature.only.damageTypes = [damageType as DamageType]
      const chaMod = character.modifierForAbility('charisma')
      feature.effects.additionalDamageOnHitDice = Dice.flatAmountDie(chaMod)
      feature.notes = `+${chaMod} dmg next hit with ${damageType} spell`

      return true
    }

    const nonBuffActions = [
      'Activate Innate Sorcery',
      'Arcane Apotheosis',
      'Bastion of Law',
      'Clockwork Cavalcade',
      'Clockwork Spells',
      'Controlled Chaos',
      'Controlled Surge',
      'Convert Sorcery Points',
      'Core Sorcerer Traits',
      'Font of Magic',
      'Font of Magic: Convert Spell Slots',
      'Font of Magic: Create Spell Slot Level 1',
      'Font of Magic: Create Spell Slot Level 2',
      'Font of Magic: Sorcery Points',
      'Innate Sorcery: Sorcery Incarnate',
      'Metamagic',
      'Metamagic Options',
      'Metamagic: Careful Spell',
      'Restore Balance',
      'Sorcerer Subclass',
      'Sorcerous Restoration',
      'Sorcery Incarnate'
    ]

    if (nonBuffActions.includes(name)) return feature.skip()

    return false
  }
}
