class dice {
  constructor(faces) {
    // For standard dice, create faces from 1 to N with probability 1
    if (faces > 0) {
      for (let i = 1; i <= faces; i++) {
        this[i] = 1
      }
    }
    // Special case: for 0 faces, create a single face with value 0
    else if (faces === 0) {
      this[0] = 1
    }

    // Add non-enumerable private property for internal state
    Object.defineProperty(this, 'private', {
      enumerable: false,
      writable: true,
      value: {}
    })
  }
}

dice.prototype.keys = function () {
  return Object.keys(this).map((key) => parseKey(key))
}

dice.prototype.maxFace = function () {
  return Math.max.apply(null, this.keys())
}

dice.prototype.minFace = function () {
  return Math.min.apply(null, this.keys())
}

dice.prototype.values = function () {
  return this.keys().map((key) => this[key])
}

dice.prototype.total = function () {
  return this.values().reduce((sum, value) => sum + value, 0)
}

function parseKey(key) {
  if (key === 'false') return false
  if (key === 'true') return true
  var ret = parseFloat(key)
  if (isNaN(ret)) return key
  return ret
}

dice.prototype.increment = function (val, count) {
  if (val in this) {
    this[val] += count
  } else {
    this[val] = count
  }
}

dice.prototype.normalize = function (scalar) {
  const ret = new dice()
  this.keys().forEach((key) => (ret[key] = this[key] * scalar))
  return ret
}

function dfunc(name, f, diceConstructor) {
  dice.prototype[name] = function (d) {
    // Create new dice using custom constructor or default
    const ret = diceConstructor ? diceConstructor() : new dice()
    const isScalar = d.constructor.name === 'Number'

    // For each face of the first die
    this.keys().forEach((key) => {
      if (isScalar) {
        // If second argument is a number, apply operation directly
        ret.increment(f(key, d), this[key])
      } else {
        // If second argument is a die, apply operation between all face combinations
        d.keys().forEach((key2) => {
          ret.increment(f(key, key2), d[key2] * this[key])
        })
      }
    })

    return ret
  }
}

dice.prototype.advantage = function () {
  return this.max(this)
}

dfunc('add', function (a, b) {
  return a + b
})

dfunc('addNonZero', function (a, b) {
  if (a != 0) return a + b
  return a
})

dfunc('subtract', function (a, b) {
  return a - b
})

dfunc('multiply', function (a, b) {
  return (a == 0 ? 0 : 1) * b
})

dice.prototype.changeFace = function (old, n) {
  const ret = { ...this }

  if (ret[old]) {
    ret[n] = ret[old]
    delete ret[old]
  }

  return Object.assign(new dice(), ret)
}

dice.prototype.deleteFace = function (n) {
  var ret = Object.assign(new dice(), this)
  delete ret[n]
  return ret
}

dice.prototype.reroll = function (d) {
  // Convert number input to scalar dice if needed
  if (d.constructor.name === 'Number') {
    d = scalarDice(d)
  }

  // Create a copy of current dice and remove faces to be rerolled
  let removed = Object.assign(new dice(), this)
  d.keys().forEach((face) => {
    removed = removed.deleteFace(face)
  })

  // Create new dice combining original and rerolled faces
  let ret = new dice()
  this.keys().forEach((face) => {
    // If face was removed (rerolled), add another roll
    if (!removed[face]) {
      ret = ret.combine(this)
    }
    // Add the remaining faces
    ret = ret.combine(removed)
  })

  return ret
}

dfunc('max', function (a, b) {
  return Math.max(a, b)
})

dfunc('advantage', function (a, b) {
  return Math.max(a, b)
})
dice.prototype.advantage.unary = true

dfunc('min', function (a, b) {
  return Math.min(a, b)
})

dfunc('ge', function (a, b) {
  return a >= b ? 0 : 1
})

dfunc(
  'dc',
  function (a, b) {
    return a >= b ? 0 : 1
  },
  function () {
    var ret = new dice()
    ret[0] = ret[1] = 0
    return ret
  }
)

dfunc(
  'ac',
  function (a, b) {
    return a >= b ? a : 0
  },
  function () {
    var ret = new dice()
    ret[0] = ret[1] = 0
    return ret
  }
)

dfunc('divide', function (a, b) {
  return a / b
})

dfunc('divideRoundDown', function (a, b) {
  return Math.floor(a / b)
})

dfunc('and', function (a, b) {
  return a && b
})

dice.prototype.percent = function () {
  return this.normalize(1 / this.total())
}

dice.prototype.average = function () {
  return this.keys().reduce((sum, key) => sum + key * this[key], 0) / this.total()
}

dice.prototype.combine = function (d) {
  const ret = new dice()

  // Copy all faces from both dice
  for (const key of this.keys()) {
    ret[key] = this[key]
  }

  for (const key of d.keys()) {
    ret.increment(key, d[key])
  }

  ret.private.critValues = d
  return ret
}

function parse(s, n) {
  const cleanStr = s.replace(/ /g, '').toLowerCase()
  const chars = [...cleanStr]
  const result = parseExpression(chars, n)

  if (chars.length) {
    throw new Error(`unexpected ${chars[0]}`)
  }

  return result
}

function parseBinaryArgument(arg, arr, n) {
  if (!arr.length || arr[0] !== 'h') {
    return parseArgument(arr, n)
  }

  assertToken(arr, 'half')
  return arg.divideRoundDown(2)
}

function parseExpression(arr, n) {
  var ret = parseArgument(arr, n)

  if (ret.constructor.name == 'Number') ret = new scalarDice(ret)

  var op
  while ((op = parseOperation(arr)) != null) {
    var arg
    if (!op.unary) arg = parseArgument(arr, n)
    else arg = ret
    // crit
    var xcrit = arr.length && arr[0] == 'x'
    var crit
    if (xcrit) {
      assertToken(arr, 'x')
      crit = true
    } else {
      crit = arr.length && arr[0] == 'c'
    }
    if (crit) {
      assertToken(arr, 'c')
      assertToken(arr, 'r')
      assertToken(arr, 'i')
      assertToken(arr, 't')
      if (xcrit) {
        xcrit = parseNumber(arr)
      } else {
        xcrit = 1
      }
      crit = new dice()
      for (var i = 0; i < xcrit; i++) {
        var max = ret.maxFace()
        crit[max] = ret[max]
        ret = ret.deleteFace(max)
      }
      var critNormalize = crit.total()
      crit = op.apply(crit, [parseBinaryArgument(arg, arr, n)])
      critNormalize = critNormalize ? crit.total() / critNormalize : 1
    }

    var save = arr.length && arr[0] == 's'
    if (save) {
      assertToken(arr, 's')
      assertToken(arr, 'a')
      assertToken(arr, 'v')
      assertToken(arr, 'e')
      save = new dice()
      var min = ret.minFace()
      // make the face non zero for * operation
      save[min > 0 ? min : 1] = ret[min]
      var saveNormalize = save.total()
      ret = ret.deleteFace(min)
      save = op.apply(save, [parseBinaryArgument(arg, arr, n)])
      saveNormalize = saveNormalize ? save.total() / saveNormalize : 1
    }

    var normalize = ret.total()
    ret = op.apply(ret, [arg])
    normalize = normalize ? ret.total() / normalize : 1
    if (crit) {
      crit = crit.normalize(normalize)
      ret = ret.normalize(critNormalize)
      ret = ret.combine(crit)
      normalize *= critNormalize
    }

    if (save) {
      save = save.normalize(normalize)
      ret = ret.normalize(saveNormalize)
      ret = ret.combine(save)
      normalize *= saveNormalize
    }
  }
  return ret
}

function assertToken(s, c, ret) {
  // Verify each character in the expected token matches the input
  for (let char of c) {
    const found = s.shift()
    if (found !== char) {
      throw new Error(`expected character '${char}', found '${found}'`)
    }
  }
  return ret
}

function parseNumber(s, n) {
  // Accumulate digits into a string
  let numberStr = ''

  // Keep going while we have digits or 'n' (parameter substitution)
  while (s.length && isNumber(s[0])) {
    if (s[0] === 'n') {
      s.shift()
      numberStr += n // Replace 'n' with the parameter value
    } else {
      numberStr += s.shift()
    }
  }

  // Validate we got at least one digit
  if (!numberStr.length) {
    throw new Error(`Expected number, found: ${s[0] || 'end of input'}`)
  }

  // Convert to integer and return
  return parseInt(numberStr, 10)
}

function opDiceInternal(d, ret, i, collect, freq, f) {
  // Base case: when we've processed all dice
  if (i === d.length) {
    return ret.combine(scalarDice(f(collect)).normalize(freq))
  }

  // Get current die and its face values
  const currentDie = d[i]
  const faces = currentDie.keys()

  // Recursively process each face value
  for (const face of faces) {
    collect.push(face)
    ret = opDiceInternal(d, ret, i + 1, collect, freq * currentDie[face], f)
    collect.pop()
  }

  return ret
}

function opDice(d, f) {
  return opDiceInternal(d, new dice(), 0, [], 1, f)
}

function keepN(n, low) {
  return function (collect) {
    // Create a copy of the array to avoid modifying the original
    const values = collect.slice()

    // Sort values in ascending or descending order based on 'low' parameter
    values.sort((a, b) => (low ? a - b : b - a))

    // Keep only the first n values
    const kept = values.slice(0, n)

    // Sum the kept values
    return kept.reduce((sum, value) => sum + value, 0)
  }
}

function multiplyDice(n, d) {
  // Handle base cases
  if (n === 0) return new dice(0)
  if (n === 1) return d

  // Use recursive divide-and-conquer approach for multiplication
  const half = Math.floor(n / 2)
  let result = multiplyDice(half, d)

  // Double the result and add extra die if n is odd
  result = result.add(result)
  if (n % 2 === 1) {
    result = result.add(d)
  }

  return result
}

function scalarDice(n) {
  var ret = new dice()
  ret[n] = 1
  return ret
}

function multiplyDiceByDice(dice1, dice2) {
  // Convert number inputs to scalar dice
  if (dice1.constructor.name === 'Number') dice1 = scalarDice(dice1)
  if (dice2.constructor.name === 'Number') dice2 = scalarDice(dice2)

  let ret = new dice()
  const faces = {}
  const numbers = dice1.keys()
  let faceNormalize = 1

  // Process each face value of the first die
  for (const key of numbers) {
    let face
    if (dice2.private.keep) {
      // Handle 'keep' operations by creating repeated dice
      const repeat = Array(key).fill(dice2)
      face = opDice(repeat, dice2.private.keep)
    } else {
      // Regular multiplication
      face = multiplyDice(key, dice2)
    }
    faceNormalize *= face.total()
    faces[key] = face
  }

  // Combine and normalize results
  for (const key in faces) {
    const face = faces[key]
    const normalizedFace = face.normalize((dice1[key] * faceNormalize) / face.total())
    ret = ret.combine(normalizedFace)
  }

  ret.private.critValues = {}
  return ret
}

function isNumber(c) {
  return (c >= '0' && c <= '9') || c === 'n'
}

// Check if array 'arr' starts with the expected string 'expected'
// Return false if expected string is longer than array or if characters don't match
function peek(arr, expected) {
  // Return false if expected string is longer than remaining array elements
  if (expected.length > arr.length) return false

  // Compare each character of expected string with array elements
  for (let i = 0; i < expected.length; i++) {
    if (arr[i] !== expected.charAt(i)) return false
  }

  return true
}

function peekIsNumber(arr, index) {
  // Check if element at given index is a number
  if (index >= arr.length) return false
  return isNumber(arr[index])
}

function parseDice(s, n) {
  // Check for 'hd' prefix (heroic dice that reroll 1s)
  const isHeroic = peek(s, 'hd') && peekIsNumber(s, 2)

  if (isHeroic) {
    assertToken(s, 'h')
    assertToken(s, 'd')
  }
  // Check for regular 'd' prefix
  else if (peek(s, 'd') && peekIsNumber(s, 1)) {
    assertToken(s, 'd')
  } else {
    return
  }

  // Parse the number of faces
  const faces = parseNumber(s, n)
  let result = new dice(faces)

  // For heroic dice, remove 1s and combine with original
  if (isHeroic) {
    result = result.deleteFace(1).combine(result)
  }

  return result
}

function parseArgument(s, n) {
  let result = parseArgumentInternal(s, n)

  let nextArg
  while ((nextArg = parseArgumentInternal(s, n))) {
    result = multiplyDiceByDice(result, nextArg)
  }

  return result
}

function parseKeep(s, n) {
  // Check if keeping lowest ('l') or highest ('h')
  let isLowest = false
  if (peek(s, 'l')) {
    assertToken(s, 'l')
    isLowest = true
  } else if (peek(s, 'h')) {
    assertToken(s, 'h')
  } else {
    return
  }

  // Parse the number of dice to keep
  const keepCount = parseNumber(s, n)

  // Parse the dice expression to apply the keep operation to
  const result = parseArgumentInternal(s, n)
  result.private.keep = keepN(keepCount, isLowest)
  return result
}

function parseArgumentInternal(s, n) {
  // Return if no characters left to parse
  if (!s.length) return

  // Get the first character
  const c = s[0]

  switch (c) {
    case '(':
      // Handle parenthesized expressions
      s.shift() // Remove opening parenthesis
      return assertToken(s, ')', parseExpression(s, n))

    case 'h': // Halfling dice
    case 'd': // Regular dice
      return parseDice(s, n)

    case 'k': // Keep highest/lowest dice
      assertToken(s, 'k')
      return parseKeep(s, n)

    // Parse numeric values (including parameter 'n')
    case '0':
    case '1':
    case '2':
    case '3':
    case '4':
    case '5':
    case '6':
    case '7':
    case '8':
    case '9':
    case 'n':
      return parseNumber(s, n)
  }
}

function parseOperation(s) {
  // Return if no characters left or closing parenthesis
  if (!s.length || s[0] === ')') return

  // Get first character and determine operation
  const c = s[0]
  switch (c) {
    // AC check
    case 'a':
      assertToken(s, 'ac')
      return dice.prototype.ac

    // DC check
    case 'd':
      assertToken(s, 'dc')
      return dice.prototype.dc

    // Advantage roll
    case '!':
      assertToken(s, '!')
      return dice.prototype.advantage

    // Maximum of values - Advantage
    case '>':
      assertToken(s, '>')
      return dice.prototype.max

    // Minimum of values - Disadvantage
    case '<':
      assertToken(s, '<')
      return dice.prototype.min

    // Add non-zero values
    case '+':
      assertToken(s, '+')
      return dice.prototype.addNonZero

    // Add all values
    case '~':
      assertToken(s, '~')
      assertToken(s, '+')
      return dice.prototype.add

    // Subtract values
    case '-':
      assertToken(s, '-')
      return dice.prototype.subtract

    // Combine dice
    case '&':
      assertToken(s, '&')
      return dice.prototype.combine

    // Reroll specified faces
    case 'r':
      assertToken(s, 'reroll')
      return dice.prototype.reroll

    // Multiply values
    case '*':
      assertToken(s, '*')
      return dice.prototype.multiply
  }
}

export function evalDice(diceString) {
  return parse(diceString, 0)
}
