import { Dice } from '../Dice'

import { FeatureParserBase } from './FeatureParserBase'

export class FeatureParserWarlock {
  static parse(parser: FeatureParserBase) {
    const thisClass = 'Warlock'
    if (!parser.validate(thisClass)) return false
    const { name, only, action, feature, effects, character, requiredLevel, isSubclassFeature } = parser

    if (name === 'Lifedrinker') {
      if (action.id !== 9414094) return feature.skip()
      only.pactWeapon = true
      effects.additionalDamageOnHitDice = new Dice(action.dice)
      feature.defaultEnabled = true
      return true
    }

    if (name === 'Hexblade’s Curse') {
      if (isSubclassFeature) return feature.skip()
      only.attackRoll = true
      effects.expandedCrit = true
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(character.proficiencyBonus())
      feature.notes = '+' + character.proficiencyBonus().toString() + ' (PB) dmg per hit, expanded crit range'
      feature.usesLimitedResource = true
      return true
    }

    // TODO not working in Warlock
    if (name === 'Hurl Through Hell') {
      if (isSubclassFeature) return feature.skip()

      only.attackRoll = true
      only.oncePerTurn = true
      if (action.id === 1039) {
        effects.additionalDamageOnHitDice = Dice.Create(10, 10)
        feature.name = `${name} (2014)`
      } else {
        effects.additionalDamageOnHitDice = new Dice(action.dice)
      }
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'Entropic Ward') {
      if (isSubclassFeature) return feature.skip()
      only.attackRoll = true
      only.oncePerTurn = true
      effects.advantage = true
      return true
    }

    if (name === 'Grave Touched') {
      only.oncePerTurn = true
      effects.additionalDamageDiceOnHit = 1
      feature.name = 'Grave Touched (Form of Dread)'
      feature.notes = '1 additional necrotic damage die on hit'
      return true
    }

    if (name === 'Hex Warrior') {
      if (requiredLevel) return feature.skip()

      only.hexWeapon = true
      only.nonTwoHandedWeapon = true
      effects.useCharismaAbilityModForAttacks = true
      feature.defaultEnabled = true
      feature.notes = `Use Charisma for attacks with one-handed Hex weapons`
      return true
    }

    const nonBuffActions = [
      '12: Ability Score Improvement',
      '16: Ability Score Improvement',
      '8: Ability Score Improvement',
      'Ability Score Improvement',
      'Accursed Specter',
      'Archfey Spells',
      'Armor of Hexes',
      'Armor of Shadows',
      'Ascendant Step',
      'Beguiling Defenses',
      'Bewitching Magic',
      'Blessing of the Raven Queen (Resistance)',
      'Cloak of Flies',
      'Contact Patron',
      'Core Warlock Traits',
      'Create Pact Weapon',
      'Dark One’s Blessing',
      'Dark One’s Own Luck',
      'Devil’s Sight',
      'Devouring Blade',
      'Efreeti',
      'Eldritch Invocation Options',
      'Eldritch Invocations',
      'Eldritch Master',
      'Eldritch Mind',
      'Eldritch Smite',
      'Eldritch Spear',
      'Elemental Gift',
      'Elemental Gift (Efreeti)',
      'Elemental Gift - Flight',
      'Epic Boon',
      'Equipment',
      'Expanded Spell List',
      'Fiend Spells',
      'Fiendish Resilience',
      'Fiendish Vigor',
      'Genie Kind',
      "Genie's Vessel: Bottled Respite",
      'Genie’s Vessel',
      'Hit Points',
      'Limited Wish',
      'Maddening Hex',
      'Magical Cunning',
      'Mask of Many Faces',
      'Master of Hexes',
      'Misty Escape',
      'Misty Step: Disappearing Step',
      'Misty Step: Dreadful Step',
      'Misty Step: Refreshing Step',
      'Misty Step: Taunting Step',
      'Mystic Arcanum',
      'Mystic Arcanum (6th level)',
      'Mystic Arcanum (7th level)',
      'Mystic Arcanum (8th level)',
      'Mystic Arcanum (9th level)',
      'Mystic Arcanum (Level 6 Spell)',
      'Mystic Arcanum (Level 7 Spell)',
      'Mystic Arcanum (Level 8 Spell)',
      'Mystic Arcanum (Level 9 Spell)',
      'Otherworldly Patron',
      'Pact Boon',
      'Pact Boon: Pact of the Talisman',
      'Pact Magic',
      'Pact of the Blade: Bond',
      'Pact of the Blade: Conjure',
      'Pact of the Chain: Attack',
      'Proficiencies',
      'Repelling Blast',
      'Sanctuary Vessel',
      'Sign of Ill Omen',
      'Steps of the Fey',
      'Thirsting Blade',
      'Warlock Subclass',
      'Whispers of the Grave',
      'Witch Sight'
    ]

    if (nonBuffActions.includes(name)) return feature.skip()
    return false
  }

  static parseClassOptions(parser: FeatureParserBase) {
    const { feature, action, character } = parser
    const name = feature.name
    const thisClass = 'Warlock'
    const id = action.id
    const { only, effects } = feature

    const classLevel = character.classLevel(thisClass)
    const requiredLevel = action.requiredLevel
    if (classLevel === 0) return false
    if (feature.skipped()) return false
    if (requiredLevel && classLevel < requiredLevel) return feature.skip()

    if (name === 'Pact of the Blade') {
      if (character.is2024Class(thisClass)) {
        only.pactWeapon = true
        feature.notes = `Use Charisma for attacks with Pact weapon`
      } else {
        // if hex warrior us enabled…
        only.featureNameEnabled = 'Hex Warrior'

        // Do all the same stuff as Hex Warrior, but allow any melee weapon
        only.hexWeapon = true
        only.meleeWeapon = true
        feature.notes = 'Hex Warrior weapon can be any melee weapon'
      }

      effects.useCharismaAbilityModForAttacks = true
      feature.defaultEnabled = true
      return true
    }

    if (feature.name === 'Eldritch Smite') {
      only.attackRoll = true
      only.pactWeapon = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(1 + character.warlockSpellLevel(), 8)
      feature.usesLimitedResource = true
      return true
    }

    if (feature.name.startsWith("Genie's Wrath")) {
      only.attackRoll = true
      only.oneBeam = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(character.proficiencyBonus())
      return true
    }

    if (name === 'Agonizing Blast' && id === 133) {
      const chaMod = character.modifierForAbility('charisma')
      only.spellNamed = 'Eldritch Blast'
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(chaMod)
      feature.defaultEnabled = true
      feature.notes = `+${chaMod} dmg to Eldritch Blast`
      feature.name = `${feature.name} (2014)`
      return true
    }

    if (name.startsWith('Agonizing Blast (')) {
      const chaMod = character.modifierForAbility('charisma')
      only.cantrip = true
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(chaMod)
      feature.defaultEnabled = true
      feature.name = 'Agonizing Blast'
      feature.notes = `+${chaMod} dmg to cantrip attacks`
      return true
    }

    if (name === 'Improved Pact Weapon') {
      feature.only.pactWeapon = true
      feature.only.mundaneWeapon = true // for +1 bonus
      feature.effects.canAlsoUseBowsAndCrossbows = true
      feature.effects.additionalToHitDice = Dice.flatAmountDie(1)
      feature.effects.additionalDamageOnHitDice = Dice.flatAmountDie(1)

      // The weapon gains a +1 bonus to its attack and damage rolls, unless it is a magic weapon that already has a bonus to those rolls.

      feature.defaultEnabled = true
      feature.notes = 'Pact weapon is +1 if not already magical, can be bow or crossbow'

      return true
    }

    if (name === 'Lifedrinker') {
      if (id !== 148) return feature.skip()
      const chaMod = character.modifierForAbility('charisma')
      only.pactWeapon = true
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(chaMod)
      feature.defaultEnabled = true
      feature.name = `${feature.name} (2014)`
      feature.notes = `+${chaMod} dmg to pact weapon attacks`
      return true
    }

    const nonBuffOptions = ['Pact of the Tome', 'Pact of the Chain', 'Pact of the Talisman']
    if (nonBuffOptions.includes(name)) return feature.skip()

    return false
  }
}
