import { Thead, Tr, Th, HStack, Text, Spacer } from '@chakra-ui/react'
import React, { useState } from 'react'
import { ActionLevelMap } from '../../Common/Interfaces'
import { Dispatcher } from '../../Common/Types'
import { AttackAction } from '../../DDB/AttackAction'
import { TABLE_HEADER_COLOR } from '../CharacterSheet'
import { AttackRow } from '../Controls/AttackRow'
import { LevelStepper } from '../Controls/LevelStepper'

export function SpellTable({
  title,
  spells,
  fixedSpellLevel,
  highestLevelSlot,
  actionIdState,
  showRange
}: {
  title: string
  spells: AttackAction[]
  fixedSpellLevel: number
  highestLevelSlot: number
  actionIdState: [ActionLevelMap[], Dispatcher<ActionLevelMap[]>]
  showRange: boolean
}) {
  const [level, setLevel] = useState(fixedSpellLevel || 1)
  const [actionIdList, setActionIdList] = actionIdState

  const spellRows = spells
    .sort((a, b) => a.attributes.level - b.attributes.level)
    .map((spell: AttackAction) => (
      <AttackRow
        key={spell.attributes.id}
        attack={spell}
        actionIdState={[actionIdList, setActionIdList]}
        level={level}
        showRange={showRange}
        url={spell.attributes.ddbURL}
      />
    ))

  if (spells.length === 0) {
    return null
  }

  return (
    <Thead>
      <Tr textColor={TABLE_HEADER_COLOR}>
        <Th fontSize={'medium'} pt={4}>
          <HStack justifyContent="space-between">
            <Text>{title}</Text>
            {highestLevelSlot > 1 && (
              <LevelStepper
                level={level}
                onLevelChange={setLevel}
                highestLevelSlot={highestLevelSlot}
                levelControls={fixedSpellLevel === 0}
              />
            )}
            <Spacer />
          </HStack>
        </Th>
        {showRange && <Th>Range</Th>}
        <Th>To Hit / DC</Th>

        <Th>Damage</Th>
      </Tr>
      {spellRows}
    </Thead>
  )
}
