import { ActionParser } from './ActionParser'
import { AttackAction } from '../AttackAction'
import { Range } from '../CharacterJSON/Range'
import { Dice } from '../Dice'
import { Activation } from '../CharacterJSON/Activation'
import { Action } from '../CharacterJSON/Actions'
import { Weapon } from '../Weapon'

export class ActionParserWeaponFeat {
  static parse(actionParser: ActionParser, featAction: Action, weapons: Weapon[], attackActions: AttackAction[]) {
    const character = actionParser.character

    const name = featAction.name

    if (name === 'Reactive Strike') {
      for (const weapon of weapons) {
        if (weapon.attributes.is2024Polearm) {
          const reactiveStrike = AttackAction.CreateFromWeapon(weapon, character)
          reactiveStrike.name = weapon.name
          reactiveStrike.id = featAction.id + weapon.id
          reactiveStrike.attributes.id = featAction.id + weapon.id
          reactiveStrike.attributes.displayAttributes.push('Sentinel: Reactive Strike')

          reactiveStrike.activation = Activation.Reaction()
          attackActions.push(reactiveStrike)
        }
      }

      return true
    }

    if (name === 'Hew') {
      for (const weapon of weapons) {
        if (weapon.hasProperty('Heavy')) {
          const hewAction = AttackAction.CreateFromWeapon(weapon, character)
          hewAction.name = `${featAction.name} (${weapon.customizedName ? weapon.customizedName : weapon.name})`
          hewAction.activation = new Activation(featAction.activation)
          hewAction.id = featAction.id + weapon.id
          hewAction.attributes.id = hewAction.id
          attackActions.push(hewAction)
        }
      }
      return true
    }

    if (name === 'Pole Strike' || name === 'Polearm Master - Bonus Attack') {
      const poleStrike = name === 'Pole Strike'
      const pamBonus = name === 'Polearm Master - Bonus Attack'

      for (const weapon of weapons) {
        if ((pamBonus && weapon.attributes.isPolearm) || (poleStrike && weapon.attributes.is2024Polearm)) {
          const polearmAttackAction = AttackAction.CreateFromWeapon(weapon, character)

          const toHit = polearmAttackAction.attackMod
          const dice = Dice.Create(1, 4, polearmAttackAction.dice.fixedValue)
          const attributes = { ...weapon.weaponAttributes() }
          if (weapon.hasProperty('Thrown')) {
            // The only thrown polearm is a spear, give it 5ft melee range
            attributes.range = Range.makeWeaponRange(5)
          }

          attributes.displayAttributes.push(pamBonus ? 'Polearm Master Bonus' : 'Polearm Master: Pole Strike')

          attributes.id = attributes.id + featAction.id
          const ba = Activation.BonusAction()
          const pamBonusAttack = new AttackAction(attributes.id, weapon.name, toHit, dice, attributes, ba)
          attackActions.push(pamBonusAttack)
        }
      }

      return true
    }

    return false
  }
}
