import * as Parsers from './FeatureParsers'
import { FeatureParserBase } from './FeatureParserBase'
import { FeatureSource } from '../FeatureSource'

export class FeatureParserClassFeature {
  static parse(parser: FeatureParserBase): boolean {
    if (parser.feature.featureSource !== FeatureSource.Class) return false

    if (Parsers.Metamagic.parse(parser)) return true
    if (Parsers.Rogue.parse(parser)) return true
    if (Parsers.Barbarian.parse(parser)) return true
    if (Parsers.Paladin.parse(parser)) return true
    if (Parsers.Ranger.parse(parser)) return true
    if (Parsers.Fighter.parse(parser)) return true
    if (Parsers.Artificer.parse(parser)) return true
    if (Parsers.Bard.parse(parser)) return true
    if (Parsers.Monk.parse(parser)) return true
    if (Parsers.Cleric.parse(parser)) return true
    if (Parsers.Wizard.parse(parser)) return true
    if (Parsers.Sorcerer.parse(parser)) return true
    if (Parsers.Druid.parse(parser)) return true
    if (Parsers.Warlock.parse(parser)) return true

    const nonBuffActions = [
      'Equipment',
      'Epic Boon',
      'Extra Attack',
      'Hit Points',
      'Ability Score Improvement',
      '8: Ability Score Improvement',
      '12: Ability Score Improvement',
      '16: Ability Score Improvement',
      'Proficiencies',
      'Spellcasting',
      'Weapon Mastery'
    ]

    if (nonBuffActions.includes(parser.name)) return parser.feature.skip()

    parser.logUnknownAction()
    return false
  }
}
