import { ABILITY } from './Types'

export const DISCORD_URL: string = 'http://discord.gg/Vphu9Gr8V8'

export const ABILITY_NAMES: ABILITY[] = ['strength', 'dexterity', 'constitution', 'intelligence', 'wisdom', 'charisma']

export const FIGHTING_STYLES = [
  'Great Weapon Fighting',
  'Archery',
  'Dueling',
  'Two-Weapon Fighting',
  'Unarmed Fighting',
  'Blessed Warrior',
  'Blind Fighting',
  'Defense',
  'Druidic Warrior',
  'Interception',
  'Protection',
  'Superior Technique',
  'Thrown Weapon Fighting'
]

// Non-buff actions is just for development purposes, to see which stuff is sneaking through unhandled.
// These should all be moved eventually to classes, and this list drained.
// LATER - Though maybe it's a slight performance boost if we filter stuff out from this FIRST? Or is that even SLOWER?
export const NON_BUFF_ACTIONS = [
  ' Radiant Soul',
  ' Shadow Martyr',
  '16: Weapon Mastery',
  '4: Ability Score Improvement',
  'Aberrant Dragonmark',
  'Ability Score Increase',
  'Ability Score Increases',
  'Acid Resistance',
  'Acolyte of Nature',
  'Aereni Elf',
  'Age',
  'Agile Parry',
  'Alchemist Spells',
  'Alignment',
  'Alter Memories',
  'Ambush',
  'Among the Dead',
  'Amphibious',
  'Ancestral Legacy',
  'Ancestral Protectors',
  'Animal Speaker',
  'Animating Performance',
  'Animating Performance: Animate',
  'Animating Performance: Dancing Item',
  'Arcane Archer Lore',
  'Arcane Shot',
  'Arcane Shot Options',
  'Arcane Ward - Hit Points',
  'Armored Casing',
  'Artificer Initiate',
  'Artificer Subclass',
  'Artificer’s Lore',
  'Ascendant Aspect',
  'Aspect of the Beast',
  'Aspect of the Wilds',
  'Aspect of the Wyrm',
  'Aspect of the Wyrm: Frightful Presence',
  'Astral Fire',
  'Astral Knowledge',
  'Astral Trance',
  'Augment Breath',
  'Aura Improvements',
  'Aura of Conquest',
  'Aura of Hate',
  'Aura of Warding',
  'Aura of the Sentinel',
  'Awakened Mind',
  'Awakened Spellbook',
  'Awakened Spellbook: Replace Damage',
  'Awakened Spellbook: Ritual Spells',
  'Balm of the Summer Court',
  'Battle Smith Spells',
  'Bear',
  'Beasts',
  'Bend Luck',
  'Benign Transposition',
  'Bestial Soul',
  'Bestow Water Breathing',
  'Bewitching Whispers',
  'Bladesong',
  'Blazing Revival',
  'Blessed Warrior',
  'Blessing of the Forge',
  'Blessing of the Raven Queen',
  'Blessing of the Trickster',
  'Blessings of Knowledge',
  'Blighted Shape',
  'Blue Dragon',
  'Bond of Fang and Scale',
  'Bonus Cantrip',
  'Bonus Cantrips',
  'Book of Ancient Secrets',
  'Born Acrobat',
  'Born to the Saddle',
  'Brave',
  'Breath of the Dragon',
  'Bulwark',
  'Call of the Hunt',
  'Call of the Shadowseeds',
  'Call of the Shadowseeds: Blighted Sapling',
  'Call of the Shadowseeds: Summon Blighted Sapling',
  'Call the Hunt',
  'Cantrip',
  'Careful Spell',
  'Cat’s Claws',
  'Cat’s Talent',
  'Cauterizing Flames',
  'Celestial Resilience',
  'Celestial Resistance',
  'Celestial Spells',
  'Chameleon Carapace',
  'Changeling Instincts',
  'Channel Divinity: Abjure Enemy',
  'Channel Divinity: Abjure the Extraplanar',
  "Channel Divinity: Artisan's Blessing",
  'Channel Divinity: Balm of Peace',
  'Channel Divinity: Champion Challenge',
  'Channel Divinity: Charm Animals and Plants',
  'Channel Divinity: Cloak of Shadows',
  'Channel Divinity: Conquering Presence',
  'Channel Divinity: Control Undead',
  'Channel Divinity: Divine',
  'Channel Divinity: Dreadful Aspect',
  'Channel Divinity: Invoke Duplicity',
  'Channel Divinity: Knowledge of the Ages',
  'Channel Divinity: Read Thoughts',
  'Channel Divinity: Turn the Faithless',
  'Channel Divinity: Turn the Tide',
  'Channel Divinity: Turn the Unholy',
  'Channel Divinity: Watcher’s Will',
  'Chemical Mastery',
  'Circle of Mortality',
  'Cleansing Touch',
  'Clockwork Magic',
  'Cloud Rune',
  'Coast',
  'Cold Resistance',
  'Command Undead',
  'Conjuration Savant',
  'Consult the Spirits',
  'Cosmic Omen ',
  'Cosmic Omen - Woe',
  'Create Thrall',
  'Creative Crescendo',
  'Creature Type',
  'Cunning Intuition',
  'Dampen Elements',
  'Dao',
  'Dark Delirium',
  'Darkvision',
  'Deathless Nature',
  'Death’s Friend',
  'Defile Ground',
  'Defile Ground: Move Corruption',
  'Defy Death',
  'Demiurgic Colossus',
  'Detect Portal',
  'Dexterous Feet',
  'Disciple of the Elements',
  'Distant Spell',
  'Divination Savant',
  'Divine Allegiance',
  'Divine Health',
  'Divine Magic',
  'Divine Sense',
  'Djinni',
  'Draconic Ancestry',
  'Draconic Disciple',
  'Draconic Gift',
  'Draconic Presence',
  'Draconic Resilience',
  'Draconic Resistance',
  'Draconic Spells',
  'Draconic Strike',
  'Dragon Ancestor',
  'Dragon Companion',
  'Dragon Wings',
  'Dragons',
  'Drake’s Breath',
  'Dread Lord',
  'Drunkard’s Luck',
  'Drunken Technique',
  'Durable Summons',
  'Dwarven Armor Training',
  'Ear for Deceit',
  'Earth Walk',
  'Echo Avatar',
  'Eladrin Season',
  'Elder Champion',
  'Elemental Cleaver: Change Damage Type',
  'Elemental Gift (Dao)',
  'Elemental Gift (Djinni)',
  'Elemental Gift (Marid)',
  'Elemental Magic',
  'Elf Weapon Training',
  'Elven Lineage',
  'Elven Lineage Spells',
  'Embodiment of the Law',
  'Empowered Healing',
  'Empowered Spell',
  'Enchantment Savant',
  'Enhanced Bond',
  'Ethereal Step',
  'Evasive Footwork',
  'Ever-Ready Shot',
  'Exalted Champion',
  'Expansive Bond',
  'Expert Divination',
  'Expert Forgery',
  'Explosive Fury',
  'Extended Spell',
  'Extra Language',
  'Eye for Detail',
  'Eyes of the Dark',
  'Eyes of the Grave',
  'Faithful Summons',
  'Fanatical Focus Reroll',
  'Far Scribe',
  'Fathomless Plunge',
  'Feline Agility',
  'Ferocious Charger',
  'Fey Ancestry',
  'Fey Gift',
  'Fey Presence',
  'Fey Step',
  'Fey Step (Spring)',
  'Fey Step (Summer)',
  'Fey Step (Winter)',
  'Fey Step - Spring',
  'Fey Wanderer Magic',
  'Fiendish Legacy',
  'Fiendish Legacy Spells',
  'Fighting Style (Fighter)',
  'Fighting Style (Paladin)',
  'Fighting Style (Ranger UA)',
  'Fighting Style (Ranger)',
  'Fighting Style: Protection',
  'Fighting Style: Thrown Weapon Fighting',
  'Fighting Style: Thrown Weapon Fighting ',
  'Fighting Style: Unarmed Fighting',
  'Fighting Style: Unarmed Fighting (armed)',
  'Fighting Style: Unarmed Fighting (grapple)',
  'Fire Damage',
  'Fire Resistance',
  'Fist of Unbroken Air',
  'Fleet of Foot',
  'Flexible Casting',
  'Flight',
  'Focused Conjuration',
  'Font of Magic: Create Spell Slot Level 3',
  'Font of Magic: Create Spell Slot Level 4',
  'Font of Magic: Create Spell Slot Level 5',
  'Forest',
  'Form of Dread',
  'Form of Dread: Transform',
  'Form of the Beast',
  'Form of the Beast: Bite',
  'Form of the Beast: Tail',
  'Form of the Beast: Tail (reaction)',
  'Foul Conjuration',
  'Foul Conjuration: Toxic Demise',
  'Frost Rune',
  'Gaze of Two Minds',
  'Gaze of Two Minds: Perceive Senses',
  'Gem Ancestry',
  'Gem Flight',
  'Ghost Walk',
  'Ghostly Gaze',
  'Giant’s Havoc',
  'Giant’s Power',
  'Gift of the Sea',
  'Githyanki Psionics',
  'Glide',
  'Gnome Cunning',
  'Gnomish Cunning',
  'Gnomish Lineage',
  'Gnomish Lineage Spells',
  'Good',
  'Grasp of Hadar',
  'Grasping Tentacles',
  'Grassland',
  'Grasslands',
  'Grave Touched: Replace Damage',
  'Great Stature',
  'Greater Favored Enemy',
  'Greater Portent',
  'Grim Harvest',
  'Grim Psychometry',
  'Guardian',
  'Guardian Coil',
  'Guardian Spirit',
  'Guardian’s Shield',
  'Hadozee Dodge',
  'Halfling Nimbleness',
  'Hammering Horns',
  'Hardened Soul',
  'Hare-Trigger',
  'Healing Hands',
  'Healing Light',
  'Healing Light: Expend Healing',
  'Healing Machine',
  'Healing Touch',
  'Hearth of Moonlight and Shadow',
  'Heavy Armor Master',
  'Heightened Spell',
  'Hellish Resistance',
  'Hidden Paths',
  'High Elf Descent',
  'Hill Rune',
  'Hold the Line',
  'Horizon Walker Magic',
  'Horns',
  'Hound of Ill Omen',
  'Hour of Reaping',
  'Humanoids',
  'Hunter’s Sense',
  'Hypnotic Gaze',
  'Illusion Savant',
  'Illusory Reality',
  'Illusory Self',
  'Implement of Peace',
  'Impostor',
  'Improved Abjuration',
  'Improved Defender',
  'Improved Divine Smite',
  'Improved Duplicity',
  'Improved Flare',
  'Improved Minor Illusion',
  'Improved Shadow Step',
  'Incarnation of Corruption: Temporary Hit Points',
  'Indestructible Life',
  'Infernal Legacy',
  'Infiltrator',
  'Infuse Magic',
  'Innate Spellcasting',
  'Insightful Fighting',
  'Insightful Manipulator',
  'Inspiring Surge',
  'Instinctive Charm',
  'Inured to Undeath',
  'Invoke Duplicity',
  'Invoke Duplicity - Move Illusion',
  'Invoke Duplicity: Cast Spells',
  'Invoke Duplicity: Distract',
  'Invoke Duplicity: Move Illusion',
  'Keen Senses',
  'Keeper of Souls',
  'Kenku Training',
  'Kensei Weapon',
  'Knowledge from a Past Life',
  'Kobold Legacy',
  'Labyrinthine Recall',
  'Lance of Lethargy',
  "Land's Stride",
  'Languages',
  'Large Form',
  'Legion of One',
  'Leporine Senses',
  'Lessons of the First Ones',
  'Light Bearer',
  'Little Giant',
  'Lucky Footwork',
  'Mage Hand Legerdemain',
  'Magic Arrow',
  'Magic Awareness',
  'Magic Initiate (Bard)',
  'Magic Initiate (Cleric)',
  'Magic Initiate (Druid)',
  'Magic Initiate (Sorcerer)',
  'Magic Initiate (Warlock)',
  'Magic Initiate (Wizard)',
  'Magic Item Analysis',
  'Magic Kensei Weapons',
  'Magic Resistance',
  'Magic-User’s Nemesis',
  'Magical Ambush',
  'Magical Guidance',
  'Malleable Illusions',
  'Maneuvers: Ambush',
  'Maneuvers: Commander’s Strike',
  'Maneuvers: Commanding Presence',
  'Maneuvers: Parry',
  'Maneuvers: Rally',
  'Manifest Echo',
  'Manifest Echo - Attack',
  'Manifest Echo - Opportunity Attack',
  'Manifest Echo - Teleport',
  'Manifest Mind',
  'Manifest Mind: Cast Spell',
  'Manifest Mind: Move',
  'Manifest Mind: Summon',
  'Mantle of Whispers',
  'Marid',
  'Mask of the Wild',
  'Master Scrivener',
  'Master Scrivener: Create Scroll',
  'Master Scrivener: Use Scroll',
  'Master Transmuter',
  'Master of Intrigue',
  'Master of Nature',
  'Master of Runes',
  'Master of Tactics',
  'Mastery of Death',
  'Mechanical Nature',
  'Mechanical Servant',
  'Medical Intuition',
  'Menacing',
  'Merfolk Weapon Training',
  'Merge with Stone',
  'Metamagic - Careful Spell',
  'Metamagic - Extended Spell',
  'Metamagic - Subtle Spell',
  'Mighty Impel',
  'Mighty Summoner',
  'Mimicry',
  'Minions of Chaos',
  'Minor Alchemy',
  'Minor Conjuration',
  'Mirthful Leaps',
  'Misdirection',
  'Misty Visions',
  'Monster Slayer Magic',
  'Mote of Potential',
  'Mountain Born',
  'Multiattack',
  'Natural Athlete',
  'Natural Recovery',
  'Naturally Stealthy',
  'Nature Speaker',
  'Nature’s Sanctuary',
  'Nature’s Ward',
  'Necromancy Savant',
  'Necrotic Husk',
  'Necrotic Husk: Revival',
  'Necrotic Resistance',
  'Nimble Escape',
  'Oath of Vengeance Spells',
  'Oath of the Ancients Spells',
  'Oathbreaker Spells',
  'Oceanic Soul',
  'One with Shadows',
  'One with the Blade',
  'One with the Word',
  'Otherworldly Presence',
  'Otherworldly Wings',
  'Owl',
  'Panther',
  'Parry',
  'Path of the Kensei',
  'Perfected Armor - Guardian',
  'Perfected Armor: Guardian',
  'Perfected Bond',
  'Performance of Creation',
  'Poison Immunity',
  'Polearm Master',
  'Portent',
  'Power of the Wilds',
  'Protective Bond',
  'Psionic Mind',
  'Psionic Sorcery',
  'Psionic Spells',
  'Psychic Defenses',
  'Psychic Resilience',
  'Purity of Spirit',
  'Quickened Spell',
  'Rabbit Hop',
  'Radiant',
  'Radiant Soul',
  'Rage beyond Death',
  'Rage of the Wilds',
  'Rage: Teleport',
  'Rallying Cry',
  'Reach to the Blaze',
  'Reclaim Potential',
  'Reflexive Resistance',
  'Relentless Avenger',
  'Relentless Endurance',
  'Resilient',
  'Resilient Hide',
  'Restorative Reagents',
  'Restriction: Knighthood',
  'Revelation in Flesh',
  'Reveler',
  'Royal Envoy',
  'Rune Carver',
  'Runic Juggernaut',
  'Runic Shield',
  'Sacred Oath',
  'Saint of Forge and Fire',
  'Salmon',
  'Searing Vengeance',
  'Secondary Arms',
  'See Invisibility',
  'Seeking Spell',
  'Sentinel',
  'Sentinel at Death’s Door',
  'Sentinel’s Intuition',
  'Shadow Arts',
  'Shadow Lore',
  'Shadow Martyr',
  'Shadow Walk',
  'Shape Shadows',
  'Shapechanger',
  'Shielding Storm',
  'Shifting',
  'Shifting Feature',
  'Silent Feathers',
  'Size',
  'Skirmisher',
  'Sleepless',
  'Song of Defense',
  'Sorcerous Origin',
  'Sorcery Points',
  'Soul of Deceit',
  'Soul of Vengeance',
  'Soul of the Forge',
  'Spectral Defense',
  'Speech of the Woods',
  'Speed',
  'Spell Thief',
  'Spells of the Mark',
  'Spider Climb',
  'Spirit Projection',
  'Spirit Projection: Project Spirit',
  'Spirit Seeker',
  'Spirit Shield',
  'Spirit Totem',
  'Spirit Walker',
  'Split Enchantment',
  'Starlight Step',
  'Starry Form: Chalice',
  'Starry Form: Dragon',
  'Steady Eye',
  'Steel Defender',
  'Stone’s Endurance',
  'Storm Aura',
  'Storm Aura: Tundra',
  'Storm Guide',
  'Storm Rune',
  'Storm Soul',
  'Storm’s Fury',
  'Stout Resilience',
  'Strength of the Grave',
  'Subrace',
  'Subtle Spell',
  'Summon Wildfire Spirit',
  'Summon Wildfire Spirit: Command',
  'Superior Attunement',
  'Superior Mobility',
  'Supernatural Defense',
  'Supernatural Resistance',
  'Survivalist',
  'Talons',
  'Telekinetic Adept: Psi-Powered Leap',
  'Telekinetic Adept: Telekinetic Thrust',
  'Telepathic Speech',
  'Tempestuous Magic',
  'Tentacle of the Deeps',
  'Tentacle of the Deeps: Attack',
  'Tentacle of the Deeps: Guardian Coil',
  'Tentacle of the Deeps: Move',
  'Tentacle of the Deeps: Summon',
  'The Third Eye',
  'Thought Shield',
  'Thri-kreen Telepathy',
  'Thunderbolt Strike',
  'Tiger',
  'Tinker',
  'Tipsy Sway',
  'Tokens of the Departed',
  'Tokens of the Departed: Create Soul Trinket',
  'Tokens of the Departed: Question Spirit',
  'Tomb of Levistus',
  'Totem Spirit',
  'Totemic Attunement',
  'Totemic Attunement - Elk',
  'Touch of Death',
  'Touch of the Long Death',
  'Training in War and Song',
  'Trance',
  'Tranquility',
  'Transmuted Spell',
  'Transmuter’s Stone',
  'Tree Native',
  'Trickery Domain Spells',
  'Turn Undead (Channel Divinity)',
  'Twinned Spell',
  'Umbral Form',
  'Undead',
  'Undead Thralls',
  'Undying Nature',
  'Undying Sentinel',
  'Unearthly Recovery',
  'Unerring Eye',
  'Unleash Incarnation',
  'Unstable Backlash',
  'Unwavering Mark',
  'Unyielding Spirit',
  'Variable Trait',
  'Variant Tiefling',
  'Vengeful Ancestors',
  'Vigilant Defender',
  'Vigilant Guardian',
  'Visions of the Past',
  'Voice of Authority',
  'Voice of the Chain Master',
  'Walker in Dreams',
  'Warding Maneuver',
  'Warping Implosion',
  'Weal (Odd)',
  'Weapon Bond',
  'Whispers of the Dead',
  'Wild Magic Surge',
  'Wild Surge',
  'Wind Caller',
  'Wind Soul',
  'Wind Speaker',
  'Wings Unfurled',
  'Wizardly Quill',
  'Wolf',
  'Wondrous Invention',
  'Wood Elf Descent',
  'Words of Terror'
]
