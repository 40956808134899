import { Dice } from '../Dice'

import { FeatureParserBase } from './FeatureParserBase'

import { FeatureParserManeuvers } from './FeatureParserManeuvers'

export class FeatureParserFighter {
  static parse(parser: FeatureParserBase) {
    const thisClass = 'Fighter'
    if (!parser.validate(thisClass)) return false
    const { name, only, feature, effects, character, requiredLevel, isSubclassFeature } = parser
    const classLevel = parser.classLevel(thisClass)
    if (FeatureParserManeuvers.parse(feature, character)) return true

    if (name === 'Studied Attacks') {
      if (requiredLevel) return feature.skip()
      only.attackRoll = true
      only.oncePerTurn = true
      effects.applyToNextAttack = true
      effects.advantage = true
      feature.notes = 'Second attack is made with advantage'
      return true
    }

    if (name === 'Fire Rune') {
      only.weapon = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(2, 6)
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'Psionic Power: Psionic Strike') {
      const dieSize = character.psionicEnergyDieSize(thisClass)
      const intModifier = character.modifierForAbility('intelligence')
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(1, dieSize, intModifier)
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'Improved Critical' || name === 'Superior Critical') {
      if (!requiredLevel) return feature.skip()
      effects.expandedCrit = true
      feature.defaultEnabled = true
      feature.notes = 'Critical Hit range for attack rolls expanded by 1'
      return true
    }

    if (name === 'Telekinetic Master: Weapon Attack') {
      only.weapon = true
      only.oncePerTurn = true
      effects.convertActionToBonusAction = true
      feature.notes = 'Make a bonus action attack with a weapon'
      return true
    }

    if (name === 'Heroic Warrior') {
      if (!requiredLevel) return feature.skip()
      only.attackRoll = true
      only.oncePerTurn = true
      effects.advantage = true
      return true
    }

    if (name === 'Giant’s Might') {
      if (isSubclassFeature) return feature.skip()
      let damageDie = 6

      if (classLevel >= 18) {
        damageDie = 10
        effects.additionalMeleeRange = 5
        feature.name = 'Giant’s Might (Runic Juggernaut)'
      } else if (classLevel >= 10) {
        damageDie = 8
        feature.name = 'Giant’s Might (Great Stature)'
      }

      only.weapon = true
      effects.additionalDamageOnHitDice = Dice.Create(1, damageDie)
      feature.defaultEnabled = true
      only.oncePerTurn = true
      return true
    }

    if (name === 'Action Surge') {
      if (isSubclassFeature) return feature.skip()
      effects.actionSurge = true
      return true
    }

    if (name === 'Banishing Arrow') {
      if (classLevel < 18) return feature.skip()

      effects.additionalDamageOnHitDice = Dice.Create(2, 6)
      feature.notes = `+${effects.additionalDamageOnHitDice.diceString()} dmg next hit with bow`
      return true
    }

    if (['Piercing Arrow', 'Seeking Arrow'].includes(name)) {
      effects.additionalDamageOnHitDice = Dice.Create(classLevel >= 18 ? 2 : 1, 6)
      feature.notes = `+${effects.additionalDamageOnHitDice.diceString()} dmg next hit with bow`
      return true
    }

    if (['Bursting Arrow', 'Beguiling Arrow', 'Enfeebling Arrow', 'Grasping Arrow', 'Shadow Arrow'].includes(name)) {
      effects.additionalDamageOnHitDice = Dice.Create(classLevel >= 18 ? 4 : 2, 6)
      feature.notes = `+${effects.additionalDamageOnHitDice.diceString()} dmg next hit with bow`
      return true
    }

    if (name === 'Curving Shot') {
      effects.advantage = true // Same math as "can attack a second target if you miss"
      // TODO… but this requires a bonus action to use

      feature.notes = 'Reroll attack against new target if you miss'
      return true
    }

    if (name === 'Telekinetic Master') {
      if (isSubclassFeature) return feature.skip()
      only.spell = true
      only.oncePerTurn = true
      effects.extraAttackThisTurn = true
      feature.usesLimitedResource = true

      // TODO… add "concentrationSpellOnly"
      feature.notes = 'Bonus action attack while concentrating on spell'
      return true
    }

    if (name === 'Fighting Spirit') {
      if (isSubclassFeature) return feature.skip()
      only.weapon = true // TODO -assumption. Does this work for spell attacks?
      effects.advantage = true
      feature.usesLimitedResource = true
      feature.notes = 'Gain advantage on attacks this turn'
      return true
    }

    if (name === 'Strength before Death') {
      if (isSubclassFeature) return feature.skip()
      feature.notes = 'Extra turn if hp drops to 0'
      effects.extraTurn = true
      return true
    }

    if (name === 'Rapid Strike') {
      if (isSubclassFeature) return feature.skip()
      only.weapon = true
      only.oncePerTurn = true
      effects.extraAttackThisTurn = true
      effects.forgoAdvantageNextAttack = true
      feature.notes = 'Forgo advantage next attack to gain extra attack'
      return true
    }

    if (name === 'War Magic') {
      if (isSubclassFeature) return feature.skip()
      feature.notes = 'Can replace 1 attack with a cantrip'
      return true
    }

    if (name === 'Improved War Magic') {
      if (isSubclassFeature) return feature.skip()
      feature.notes = 'Can replace 2 attacks with cantrips'
      return true
    }

    const nonBuffActions = [
      '12: Ability Score Improvement',
      '14: Ability Score Improvement',
      '16: Ability Score Improvement',
      '4: Weapon Mastery',
      '6: Ability Score Improvement',
      '8: Ability Score Improvement',
      'Ability Score Improvement',
      'Additional Fighting Style',
      'Arcane Charge',
      'Bait and Switch',
      'Blind Fighting',
      'Bonus Proficiencies',
      'Bonus Proficiency',
      'Bulwark of Force',
      'Combat Superiority',
      'Core Fighter Traits',
      'Defense',
      'Disarming Attack',
      'Eldritch Strike',
      'Elegant Courtier',
      'Epic Boon',
      'Equipment',
      'Extra Attack',
      'Fighter Subclass',
      'Fighting Style',
      'Guarded Mind',
      'Hit Points',
      'Improved Combat Superiority',
      'Indomitable',
      'Know Your Enemy',
      'Lunging Attack',
      'Maneuver Options',
      'Maneuver: Bait and Switch',
      'Maneuver: Lunging Dash',
      'Maneuver: Parry (Str.)',
      'Maneuver: Parry (Dex.)',
      'Maneuvers',
      'Maneuvers: Bait and Switch',
      'Maneuvers: Tactical Assessment',
      'Martial Archetype',
      'Menacing Attack',
      'Precision Attack',
      'Proficiencies',
      'Psionic Power',
      'Psionic Power: Protective Field',
      'Psionic Power: Psi-Powered Leap',
      'Psionic Power: Psionic Energy Dice',
      'Psionic Power: Telekinetic Movement',
      'Psionic Power: Telekinetic Thrust',
      'Pushing Attack',
      'Quick Toss',
      'Relentless',
      'Remarkable Athlete',
      'Second Wind',
      'Second Wind: Tactical Shift',
      'Spellcasting',
      'Stone Rune',
      'Student of War',
      'Superiority Dice',
      'Survivor',
      'Tactical Assessment',
      'Tactical Master',
      'Tactical Master: Push',
      'Tactical Master: Sap',
      'Tactical Master: Slow',
      'Tactical Mind',
      'Tactical Shift',
      'Telekinetic Adept',
      'Three Extra Attacks',
      'Tireless Spirit',
      'Trip Attack',
      'Two Extra Attacks',
      'Ultimate Combat Superiority',
      'War Bond',
      'War Bond: Ritual Bonding',
      'War Bond: Summon Weapon',
      'Weapon Mastery'
    ]

    if (nonBuffActions.includes(name)) return feature.skip()

    return false
  }
}
