import { SpellSchool, FeatureName, AbilityName } from '../Common/Types'
import { Feature } from './Feature'
import { TurnAction } from './TurnAction'
import { DamageType, SpellName } from '../Common/Types'
import { ActivationType } from './CharacterJSON/Activation'
import { WeaponProperty } from './WeaponAttributes'

// TODO we could go further with this refactor and have werapon encode if it's magical, melee, unarmed, etc.
// We can also easily do bow/crossbow, etc.

export class FeatureConstraints {
  oncePerTurn: boolean = false // favored foe

  activationType?: ActivationType = undefined
  spellNamed: SpellName = undefined
  damageTypes?: DamageType[] = undefined
  spellSchool: SpellSchool | undefined = undefined
  featureNameEnabled: FeatureName = undefined
  abilityNamed?: AbilityName = undefined
  weaponProperties?: WeaponProperty[] = undefined

  attackRoll: boolean = false
  opportunityAttack: boolean = false
  weapon: boolean = false
  magicWeapon: boolean = false
  meleeWeapon: boolean = false
  meleeOrUnarmed: boolean = false // melee weapon OR unarmed strikes
  weaponOrUnarmed: boolean = false
  nonTwoHandedWeapon: boolean = false

  singleWielding: boolean = false // Dueling
  offHand: boolean = false
  bow: boolean = false // Arcane Shot
  crossbow: boolean = false // Crossbow Expert
  horns: boolean = false

  wildshapeAttack: boolean = false
  spell: boolean = false
  singleTargetSpell: boolean = false
  pactWeapon: boolean = false
  mundaneWeapon: boolean = false
  oneBeam: boolean = false
  companionAttacks: boolean = false
  raging: boolean = false
  cantripsOrWeapons: boolean = false
  cantrip: boolean = false
  spellAttack: boolean = false
  spellWithSave: boolean = false
  unarmed: boolean = false
  unarmedOrWildshape: boolean = false
  monkWeaponOrUnarmed: boolean = false
  hexWeapon: boolean = false

  appliesToCompanionAttacks: boolean = false

  maxSpellLevel: number = 0
  specificWeaponMasteryType?: string = undefined // TODO - could be used for stuff that only effects eldritch blast, etc

  stringForConstraints(): string | undefined {
    const props = this.weaponProperties
    if (props?.includes('Finesse')) return 'finesse weapon'

    if (this.meleeWeapon) return `${props?.includes('Heavy') ? 'heavy ' : ''} melee weapon`
    if (props?.includes('Light')) return 'light weapon'
    if (this.meleeOrUnarmed) return 'melee attack'
    if (this.weaponOrUnarmed) return 'weapon or unarmed strike'
    if (this.monkWeaponOrUnarmed) return 'monk weapon or unarmed strike'
    if (this.unarmedOrWildshape) return 'wildshape or unarmed strike'
    if (props?.includes('Range')) return 'ranged weapon'
    if (this.bow) return 'bow'
    if (this.crossbow) return 'crossbow'
    if (props?.includes('Ammunition')) return 'ammunition weapon'
    if (props?.includes('Thrown')) return 'thrown weapon'
    if (this.spell) return 'spell'
    if (this.spellAttack) return 'spell attack'
    if (this.unarmed) return 'unarmed strike'
    if (this.pactWeapon) return 'pact weapon'
    if (this.hexWeapon) return 'hex weapon'
    if (this.abilityNamed) return this.abilityNamed.toLowerCase()
    if (this.wildshapeAttack) return 'wildshape attack'
    if (this.attackRoll) return 'attack roll'
    if (this.magicWeapon) return 'magic weapon'
    if (props?.includes('Finesse') && props?.includes('Range')) return 'finesse or ranged weapon'
    if (this.weapon) return 'weapon'
    return undefined
  }

  featureAppliesToTurn(feature: Feature, turn: TurnAction, features: Feature[], turnActions: TurnAction[]): boolean {
    // Skip features that modify other features. These should be filtered before now, but doing this just in case.
    if (feature.modifiesAnotherFeature()) return false

    const { only } = feature

    if (only.specificWeaponMasteryType !== undefined && !turn.isWeaponType(only.specificWeaponMasteryType)) return false
    if (!feature.alsoAppliesToCompanionAttacks && !only.appliesToCompanionAttacks && turn.isCompanion()) return false // Careful - the only "also"…
    if (only.appliesToCompanionAttacks && !turn.isCompanion()) return false
    if (only.weapon && !turn.isWeapon()) return false
    if (only.unarmed && !turn.isUnarmed()) return false
    if (only.unarmedOrWildshape && !(turn.isUnarmed() || turn.isWildShapeAttack())) return false
    if (only.bow && !turn.isBow()) return false
    if (only.crossbow && !turn.isCrossbow()) return false
    if (only.magicWeapon && !turn.isMagicWeapon()) return false
    if (only.meleeWeapon && !turn.isMeleeWeapon()) return false
    if (only.weaponProperties && !turn.hasSomeWeaponProperties(only.weaponProperties)) return false
    if (only.meleeOrUnarmed && !turn.isMeleeOrUnarmed()) return false
    if (only.mundaneWeapon && !turn.isMundaneWeapon()) return false
    if (only.cantripsOrWeapons && !(turn.isWeapon() || turn.isCantrip())) return false
    if (only.cantrip && !turn.isCantrip()) return false
    if (only.maxSpellLevel > 0 && (turn.isCantrip() || turn.spellLevel() > only.maxSpellLevel)) return false
    if (only.horns && !turn.isHorns()) return false
    if (only.spellAttack && !turn.isSpellAttack()) return false
    if (only.spellWithSave && !turn.isSpellWithSave()) return false
    if (only.attackRoll && !turn.isAttackRoll()) return false
    if (only.weaponOrUnarmed && !turn.isWeapon() && !turn.isUnarmed()) return false
    if (only.monkWeaponOrUnarmed && !turn.isMonkWeapon() && !turn.isUnarmed()) return false
    if (only.singleWielding && (turn.hasWeaponProperty('Two-Handed') || turnActions.some((t) => t.isOffHand())))
      return false
    if (only.offHand && !turn.isOffHand()) return false
    if (only.spell && !turn.isSpell()) return false
    if (only.spellSchool && !turn.isSpellSchool(only.spellSchool)) return false

    if (only.pactWeapon && !turn.isPactWeapon()) return false
    if (only.hexWeapon && !turn.isHexWeapon()) return false
    if (only.nonTwoHandedWeapon && !turn.isNonTwoHandedWeapon()) return false

    if (only.singleTargetSpell && !turn.isSingleTargetSpell()) return false
    if (only.spellNamed && !turn.isSpellNamed(only.spellNamed)) return false
    if (only.featureNameEnabled && !turn.isFeatureNameEnabled(features, only.featureNameEnabled)) return false

    if (only.abilityNamed && !turn.isAbilityNamed(only.abilityNamed)) return false
    if (only.wildshapeAttack && !turn.isWildShapeAttack()) return false
    if (only.damageTypes && !turn.isDamageTypes(only.damageTypes)) return false
    if (only.raging && !features.some((feature) => feature.effects.raging)) return false
    if (only.activationType && !turn.isActivationType(only.activationType)) return false
    if (only.opportunityAttack && !turn.isOpportunityAttack()) return false

    return true
  }
}
