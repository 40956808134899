import { Dictionary } from '../Common/Types'
import { NumberMap } from '../Common/Interfaces'
import { ABILITY_NAMES } from '../Common/Constants'
import { CharacterJSON } from './CharacterJSON/CharacterJSON'
import { Utility } from '../Common/Utility'

export class AbilityScoreParser {
  static loadAbilityScores(characterData: CharacterJSON): number[] {
    const {
      stats,
      modifiers: { class: classModifiers, feat: featModifiers, race: raceModifiers },
      background,
      overrideStats,
      bonusStats,
      inventory
    } = characterData
    const abilityScores: number[] = []
    const asiBonuses: NumberMap = new NumberMap()
    const attrOverrides: NumberMap = new NumberMap()
    const baseAttributes = stats.map((item: Dictionary) => item.value)
    for (const classData of classModifiers) {
      this.loadAttributeModifiersIntoMaps(classData, asiBonuses, attrOverrides)
    }

    for (const itemData of inventory) {
      const { equipped, isAttuned } = itemData

      if (equipped && isAttuned) {
        for (const modifier of itemData.definition.grantedModifiers) {
          this.loadAttributeModifiersIntoMaps(modifier, asiBonuses, attrOverrides)
        }
      }
    }

    for (const classData of featModifiers) {
      this.loadAttributeModifiersIntoMaps(classData, asiBonuses, attrOverrides)
    }

    const grantedFeats: Dictionary[] = background?.definition?.grantedFeats ?? []
    const hasNewBackground = grantedFeats.some((feat) => feat.name === 'Ability Scores')

    for (let index = 0; index < 6; index++) {
      const scoreId = index + 1

      const base = baseAttributes[index]
      const racialStatMod = hasNewBackground ? 0 : this.getRacialStatMod(raceModifiers, scoreId)
      const bonus = this.getSubStats(bonusStats, scoreId, 0)
      const ddbOverride = this.getSubStats(overrideStats, scoreId, 0)
      const inventoryOverride = attrOverrides[index] || 0
      const override = Math.max(ddbOverride, inventoryOverride)

      let total = 0
      if (override > 0) {
        total = override
      } else {
        const asiBonus = asiBonuses[index] || 0
        total = base + bonus + racialStatMod + asiBonus
      }

      abilityScores.push(total)
    }

    return abilityScores
  }

  // TODO - copied this code from feat parsing
  // LOL - choose-an-ability-score is in there now 2024
  static loadAttributeModifiersIntoMaps(
    data: Dictionary,
    asiBonuses: NumberMap,
    attrOverrides: NumberMap,
    requiresGranted: boolean = false
  ) {
    const type = data.type
    const subType: string = data.subType
    if (subType.endsWith('-score')) {
      const subtype = data.subType
      const attrName = subtype.slice(0, -'-score'.length)
      const attrIndex = Utility.indexOfAbility(attrName)
      const value = parseInt(data.value)
      const fixedValue = parseInt(data.fixedValue)
      const isGranted: boolean = requiresGranted ? data.isGranted : true
      if (type === 'bonus' && isGranted) {
        const existingBonus = asiBonuses[attrIndex]
        if (existingBonus) {
          asiBonuses[attrIndex] = existingBonus + value
        } else {
          asiBonuses[attrIndex] = value
        }
      }
      if (type === 'set') {
        const existingBonus = attrOverrides[attrIndex]
        if (existingBonus) {
          attrOverrides[attrIndex] = Math.max(attrOverrides[attrIndex], fixedValue)
        } else {
          attrOverrides[attrIndex] = fixedValue
        }
      }
    }
  }

  static getSubStats(data: Dictionary[], scoreId: number, base: number): number {
    const index = scoreId - 1
    const stat = data[index]
    const value = stat?.value
    return value ?? base
  }

  static getRacialStatMod(statMods: Dictionary[], scoreId: number): number {
    const index = scoreId - 1
    const targetSubtype = ABILITY_NAMES[index] + '-score'

    for (const mod of statMods) {
      if (mod.type !== 'bonus') continue
      if (mod.subType === targetSubtype) return parseInt(mod.value)
    }

    return 0
  }
}
